import { useAttachAdditionalData } from "@sk/hooks";
import {
  AuthService,
  CommonService,
  NavService,
  UtilityService,
  VendorService,
} from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  SummaryCard,
} from "@sk/uis";
import { produce } from "immer";
import merge from "lodash/merge";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "./../constantService.js";
import FilterForm from "./components/FilterForm.jsx";
import Table from "./components/Table";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import MarginListModal from "./modals/MarginListModal";

// To Get The Vendor List Data
const getData = async (params) => {
  const r = await VendorService.getVendors(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await VendorService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    select:
      "name,_id,margins,city,district,state,status,vendorType,bankDetails,vendorCategory,whIds,gst_no,contact",
  };
  let d = filter || {};

  if (d?.search) {
    const isMobileNumber = CommonService.isValidMobileNumber(d.search);
    if (isMobileNumber) {
      p.filter["contact.mobile"] = d.search;
    } else {
      p.filter.$or = [
        {
          _id: d.search,
        },
        {
          name: {
            $regex: d.search,
            $options: "i",
          },
        },
        {
          gst_no: {
            $regex: d.search,
            $options: "i",
          },
        },
        {
          "contact.email": {
            $regex: d.search,
            $options: "i",
          },
        },
      ];
    }
  }

  if (d?.state) {
    p.filter.state = d.state;
  }

  if (d?.warehouse) {
    p.filter.whIds = { $in: [d.warehouse] };
  }

  // if (d?.category) {
  //   p.filter.vendorCategory = d.category;
  // }

  if (d?.status) {
    p.filter.status = d.status;
  }
  if (d?.vendor?.length) {
    p.filter._id = d?.vendor?.length > 0 ? d?.vendor[0].value._id : "";
  }
  if (d?.vendorType) {
    p.filter.vendorType = d.vendorType;
  }
  if (d?.enablePurchaseToPos) {
    p.filter.enablePurchaseToPos = d.enablePurchaseToPos;
  }
  if (d?.district?.length) {
    p.filter.district = d.district;
  }
  if (d?.town?.length) {
    p.filter.city = d.town;
  }
  const brandMarginParams = {}; // to store brand margins params
  if (d?.category?.length) {
    brandMarginParams.category = d?.category[0].value._id;
  }

  if (d?.brand?.length) {
    brandMarginParams.brand = d?.brand[0]?.value._id;
  }

  if (d?.product?.length) {
    brandMarginParams.product = d?.product[0]?.value._id;
  }

  p.filter.margins = {
    $elemMatch: brandMarginParams,
  };

  return p;
};

const rbac = {
  addButton: ["AddVendor"],
};

const ListVendor = () => {
  // React Hook From For Handling Filter Form Data
  const methods = useForm({
    defaultValues: listView.defaultFormData,
  });

  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  // For Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // Show Margin  Modal
  const [showMarginModal, setShowMarginModal] = useState(false);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Margin modal details
  const modalMarginDataRef = useRef();

  // state data
  const stateListRef = useRef([]);

  // To Store Filter Data
  const filterDataRef = useRef({
    ...listView.defaultFormData,
    wh: AuthService.getDefaultWhId(),
  });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...listView.pagination };
    loadList();
    loadSummary();
    prepareFilterLabels();
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList, loadSummary, prepareFilterLabels]);

  // Init
  const init = useCallback(() => {
    applyFilter();
  }, []);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Preparing Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(paginationRef.current, filterDataRef.current);
  }, []);

  // Summary Count card
  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;
      promises.push(getCount(params));
    });

    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  // Default State Value
  const getDefaultState = () => {
    const defaultWhState = stateListRef.current.filter((e) => {
      return AuthService.getDefaultWhState() == e.label;
    });
    return defaultWhState.length ? defaultWhState[0].value : "";
  };

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // To Handle Advance Filter
  const advFilterCb = useCallback(
    (data) => {
      if (data.status == "applied") {
        filterDataRef.current = { ...filterDataRef.current, ...data.formData };
        applyFilter();
      }
      if (data.status == "reset") {
        filterDataRef.current = { ...listView.defaultFormData };
        methods.reset({ ...listView.defaultFormData });
        applyFilter();
      }
      closeAdvFilterModal(false);
    },
    [applyFilter, closeAdvFilterModal, methods]
  );

  const filterFormCb = (data) => {
    filterDataRef.current = { ...data.formData };
    applyFilter();
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    let v = { ...filterDataRef.current };
    if (v?.wh?.length) {
      const whSelected = AuthService.getLoggedInEmpWh().filter((e) => {
        return e._id == v.wh;
      });
      v = {
        ...v,
        wh: whSelected.map((e) => {
          return { label: e.name, value: { name: e.name, _id: e._id } };
        }),
      };
    }
    if (!v.enablePurchaseToPos) {
      delete v.enablePurchaseToPos;
    }

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Open Create Modal
  const addVendor = useCallback(() => {
    NavService.openInNewTab("/vendor/manage");
  }, []);

  // To Open Edit Modal
  const editCb = useCallback(
    (id) => {
      NavService.to(router, "/vendor/manage", { id: id });
    },
    [router]
  );

  //To  View Vendor details
  const viewCb = useCallback((id) => {
    NavService.openInNewTab("/vendor/view", { id: id });
  }, []);

  const loadStateList = useCallback(async () => {
    let localList = AuthService.getLoggedInEmpWhStates();
    let r = await UtilityService.getStateList();
    let j = (r.resp || []).filter((e) => localList.indexOf(e.name) != -1);
    stateListRef.current = j.map((e) => {
      return { label: e.name, value: e.name };
    });

    applyFilter();
  }, [applyFilter]);

  const closeMarginModal = () => {
    modalMarginDataRef.current = {};
    setShowMarginModal(false);
  };

  const viewMarginCb = (d) => {
    modalMarginDataRef.current = d;
    setShowMarginModal(true);
  };

  // Resetting Filter
  const resetCb = useCallback(() => {
    filterDataRef.current = { ...listView.defaultFormData };

    methods.reset({ ...listView.defaultFormData });

    setShowAdvFilterModal(false);

    applyFilter();
  }, [applyFilter, methods]);

  const callback = useCallback(
    ({ action, id, data }) => {
      switch (action) {
        case "view":
          NavService.openInNewTab("/vendor/view", { id: id || data?._id });
          break;
        case "edit":
          NavService.to(router, "/vendor/manage", { id: id || data?._id });
          break;
        case "viewMargin":
          NavService.openInNewTab("/vendor/view", {
            id: id || data?._id,
            tab: "brandMargin",
          });
          break;
        default:
          break;
      }
    },
    [router]
  );

  return (
    <>
      {/* Page Info */}
      <div className="row">
        <div className="col">
          <PageInfo
            title="Vendor List"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
        {/* <div className="col-auto ms-auto">
          <Rbac roles={rbac.addButton}>
            <button className="btn btn-primary me-2 mt-4" onClick={addVendor}>
              <i className="bi bi-plus-circle me-1"></i> Add Vendor
            </button>
          </Rbac>
        </div> */}
      </div>

      {/* Summary card */}
      <div className="row align-self-center">
        {summaryCard.map((e, k) => (
          <div className="col-auto" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={3}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <FormProvider {...methods}>
          <FilterForm
            callback={filterFormCb}
            resetCb={resetCb}
            openAdvFilterModal={openAdvFilterModal}
          />
        </FormProvider>
      </AppCard>

      {/* Advance Filter Modal  */}
      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        resetCb={resetCb}
        formData={filterDataRef.current}
        stateListOptions={stateListRef.current}
      />

      {/* PAGINATION SUMMARY */}
      <AppCard>
        {/* Filter selected Label */}
        <div className="mb-3">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          callback={callback}
        />
      </AppCard>

      <MarginListModal
        show={showMarginModal}
        closeCb={closeMarginModal}
        data={modalMarginDataRef.current}
      />
    </>
  );
};
export default memo(ListVendor);
