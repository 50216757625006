import { Amount, TableHeader, AppCard, PageLoader, BtnLink } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";
import { useState, useEffect } from "react";
import { NavService } from "@sk/services";

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "65%",
  },
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Product Name", width: "35%" },
  { label: "MRP", width: "10%", isCentered: true },
  { label: "Received Qty", width: "8%", isCentered: true },
  { label: "Invoice Qty", width: "8%", isCentered: true },
  { label: "Case Qty", width: "10%", isCentered: true },
  { label: "InnerCase Qty", width: "8%", isCentered: true },
  { label: "Total", width: "15%", isCentered: true },
];

const ViewProductModal = ({ show, closeModalCb, data = [], grnId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
    setFilteredData(
      sortedData.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.productId &&
            item.productId.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
    setLoading(false);
  }, [data, searchTerm]);

  const viewProduct = (productId) => {
    NavService.openInNewTab("/cms/product/view", { id: productId });
  };

  const viewGrn = (grnId) => {
    NavService.openInNewTab("/purchase-order/grn/view", { id: grnId });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title">
              Products for GRN ID: {grnId}
              <BtnLink
                onClick={() => viewGrn(grnId)}
                className="fs-val-sm ms-2"
              >
                View More Details
              </BtnLink>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <AppCard>
              <div className="d-flex justify-content-between mb-3">
                <div className="fs-val-md align-self-center">
                  Total Products: {filteredData.length}
                </div>
                <input
                  type="text"
                  placeholder="Search by name or ID"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control w-50"
                />
              </div>
              {loading ? (
                <PageLoader />
              ) : (
                <table className="table table-sm">
                  <TableHeader data={headers} noBg={true} />
                  <tbody>
                    {/* No Data Found */}
                    {filteredData.length == 0 && (
                      <tr>
                        <td
                          colSpan={headers.length}
                          className="fs-val-sm text-center"
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                    {/* Data */}
                    {filteredData.length > 0 &&
                      filteredData.map((item, i) => (
                        <tr key={i} className="fs-val-sm">
                          <td className="text-center">{i + 1}</td>
                          <td>
                            <BtnLink
                              onClick={() => viewProduct(item.productId)}
                              className="fs-val-sm mb-1"
                            >
                              {item.name}
                            </BtnLink>
                            <div className="text-muted">
                              ID: {item.productId}
                            </div>
                          </td>
                          <td className="text-center">
                            <Amount value={item.price.mrp} />
                          </td>
                          <td className="text-center">
                            <div>{item.receivedQuantity} </div>
                          </td>
                          <td className="text-center">
                            <div> {item.invoiceQuantity} </div>
                          </td>
                          <td className="text-center">{item.caseQty}</td>
                          <td className="text-center">{item.innerPackQty}</td>
                          <td className="text-center">
                            <Amount
                              value={
                                item.receivedQuantity * item.price.purchasePrice
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </AppCard>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewProductModal;
