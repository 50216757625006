import { sub } from "date-fns";
import activeImg from "../assets/imgs/active.svg";

import inactiveImg from "../assets/imgs/inactive.svg";

import totalImg from "../assets/imgs/total.svg";

import totalBrandsImg from "../assets/imgs/totalBrands.svg";

import totalSKUImg from "../assets/imgs/totalSKU.svg";

import totalVendorsImg from "../assets/imgs/totalVendors.svg";

import totalWarehouseImg from "../assets/imgs/totalWarehouse.svg";

import pendingPaymentsImg from "../assets/imgs/pendingPayments.svg";

import purchaseOrderImg from "../assets/imgs/purchaseOrder.svg";

import receivedPaymentsImg from "../assets/imgs/receivedPayments.svg";

import invoicedImg from "../assets/imgs/Invoiced.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Vendor List",
    },
  ],
  // Breadcrumbs Ends Here
  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
  ],
  //Summary Card Data Ends Here
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here
  pagination: {
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  },
  // Pagination Ends Here
  formLabels: {
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    product: {
      label: "Product",
      valuePath: "[0].value.name",
    },
    vendor: {
      label: "Vendor",
      valuePath: "[0].value.name",
    },
    status: {
      label: "Status",
    },
    vendorCategory: {
      label: "Vendor Category",
    },
    vendorType: {
      label: "Vendor Type",
    },
    state: {
      label: "State",
    },
    district: {
      label: "District",
    },
    town: {
      label: "Town",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    wh: {
      label: "Warehouse",
      valuePath: "[0].value.name",
    },
    enablePurchaseToPos: {
      label: "Enable Purchase To Pos",
    },
    warehouse: {
      label: "Warehouse",
    },
    search: {
      label: "Search",
    },
  },
  // Form Labels Ends Here

  defaultFormData: {
    wh: [],
    state: "",
    status: "",
    brand: "",
    vendorCategory: "",
    vendorType: "",
    district: "",
    town: "",
    category: "",
    product: "",
    enablePurchaseToPos: "",
    vendor: "",
  },

  statusOptions: [
    { label: "All", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
  //Status Options Ends Here

  categoryOptions: [
    { label: "All", value: "" },
    { label: "Electronics", value: "Electronics" },
    { label: "FMCG", value: "FMCG" },
    { label: "Service", value: "Service" },
  ],
  // Category Options Ends Here

  vendorListHeaders: [
    { label: "Sl No", width: "5%", isCentered: true },
    { label: "Id" },
    { label: "Name" },
    { label: "Type" },
    { label: "Vendor Category", isCentered: true },
    { label: "Brand Margins", isCentered: true },
    { label: "Location", isCentered: true },
    { label: "Status", isCentered: true },
    { label: "Actions", isCentered: true },
  ],
  // Vendor List headers Ends here

  vendorTypeOptions: [
    { label: "All", value: "" },
    { label: "Cash & Carry", value: "Cash & Carry" },
    { label: "Direct Company", value: "Direct Company" },
    { label: "Distributor", value: "Distributor" },
    { label: "Others", value: "Others" },
    { label: "Service Vendor", value: "Service Vendor" },
    { label: "Wholesaler", value: "Wholesaler" },
  ],
  // Vendor Type Options Ends Here

  formVendorTypeOptions: [
    { label: "Choose Type", value: "" },
    { label: "Cash & Carry", value: "Cash & Carry" },
    { label: "Direct Company", value: "Direct Company" },
    { label: "Distributor", value: "Distributor" },
    { label: "Others", value: "Others" },
    { label: "Service Vendor", value: "Service Vendor" },
    { label: "Wholesaler", value: "Wholesaler" },
  ],
  // Vendor Type Options Ends Here

  formStatusOptions: [
    { label: "Choose Status", value: "" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
  //Status Options Ends Here

  formCategoryOptions: [
    { label: "Choose Category", value: "" },
    { label: "Electronics", value: "Electronics" },
    { label: "FMCG", value: "FMCG" },
    { label: "Service", value: "Service" },
  ],
  // Category Options Ends Here
  marginAdditionalTableDataConfig: [
    {
      key: "brand",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brand",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "category",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_category",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "product",
      api: "product",
      loadingKey: "productLoading",
      dataKey: "_product",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here
};
// LIST VIEW EDS HERE

export const manageView = {
  defaultBreadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Vendor List",
      link: "/vendor/list",
    },
    {
      name: "Add Vendor",
    },
  ],

  tabs: [
    { key: "basicDetails", tabName: "Basic Details" },
    { key: "kycDetails", tabName: "KYC Details" },
    { key: "contractDetails", tabName: "Contract Details" },
    { key: "linkWarehouse", tabName: "Linked Warehouse" },
  ],

  defaultFormValue: {
    entity: "",
    vendorType: "",
    vendorCategory: "",
    status: "",
    isWarehouseVendor: false,
    isOfficeAddressSame: false,
    warehouseDataId: "",
    enablePurchaseToPos: false,
    name: "",
    aadharDoc: [],
    panDoc: [],
    contact: [],
    gstDoc: [],
    msmeDoc: [],
    cancelChequeDoc: [],
    bankDetails: [],
    warehouseList: [],
    whIds: [],
  },

  paymentOptions: [
    { label: "Choose", value: "" },
    { label: "Cheque", value: "Cheque" },
    { label: "Bank Transfer", value: "Bank transfer" },
  ],

  statusOptions: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ],
  //Status Options Ends Here

  defaultBankDetailsFormData: {
    bankName: "",
    accountNumber: "",
    isActive: false,
    accountHolderName: "",
    branchName: "",
    bankId: "",
    ifsc: "",
    editIndex: -1,
  },

  bankDetailsHeaders: [
    { label: "SL.No", isCentered: true },
    { label: "Bank Name" },
    { label: "Account Number" },
    { label: "Account Holder Name" },
    { label: "Branch Name" },
    { label: "IFSC" },
    { label: "Is Active", isCentered: true },
    { label: "Actions", isCentered: true },
  ],

  defaultContactDetailsFormData: {
    name: "",
    isOwner: false,
    designation: "",
    email: "",
    mobile: "",
    editIndex: -1,
  },

  contactDetailsHeaders: [
    { label: "SL.No", isCentered: true },
    { label: "Is Owner" },
    { label: "Name" },
    { label: "Designation" },
    { label: "Email" },
    { label: "Mobile" },
    { label: "Actions", isCentered: true },
  ],

  defaultBrandMarginDetailsFormData: {
    _brand: [],
    brand: "",
    _category: [],
    category: "",
    _product: [],
    product: "",
    whId: "",
    margin: "",
    weightage: "",
    start_date: "",
    end_date: "",
    editIndex: -1,
    marginType: "Brand-Category",
  },

  brandMarginsHeaders: [
    { label: "SL.No", isCentered: true },
    { label: "Brand/Category" },
    { label: "Product" },
    { label: "Warehouse" },
    { label: "Margin Type", isCentered: true },
    { label: "Margin(%)", isCentered: true },
    { label: "Weightage", isCentered: true },
    { label: "Start date", isCentered: true },
    { label: "Expiry date", isCentered: true },
    { label: "Actions", isCentered: true },
  ],

  attachBrandMarginAdditionalDataConfig: [
    {
      key: "category",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_categoryDetails",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "brand",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brandDetails",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "product",
      api: "product",
      loadingKey: "productLoading",
      dataKey: "_productDetails",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  marginTypeOptions: [
    { label: "Brand/Category", value: "Brand-Category" },
    { label: "Product", value: "Product" },
  ],
};
// Manage View

export const viewVendor = {
  //  BreadCrumb Config
  breadcrumbs: [
    {
      name: "Vendor List",
      link: "/vendor/list",
    },
    {
      name: "Vendor Details",
    },
  ],

  tabs: [
    { key: "po", tabName: "PO" },
    { key: "grn", tabName: "GRN" },
    { key: "intakeSummary", tabName: "Intake Summary" },
    { key: "brandMargin", tabName: "Brand Margin" },
    { key: "kyc", tabName: "KYC Details" },
    { key: "account", tabName: "Account Statement" },
    { key: "product", tabName: "Products" },
    { key: "auditLog", tabName: "Audit Log" },
  ],

  attachBrandMarginAdditionalDataConfig: [
    {
      key: "category",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_category",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "brand",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brand",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "product",
      api: "product",
      loadingKey: "productLoading",
      dataKey: "_product",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  // To Attach Additional Config
  attachAdditionalDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_lastUpdatedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  tabData: {
    brandMargin: {
      brandMarginsHeaders: [
        { label: "SL.No", isCentered: true },
        { label: "Brand" },
        { label: "Category" },
        { label: "Product", width: "10%" },
        { label: "Warehouse", width: "10%" },
        { label: "Margin Type", isCentered: true },
        { label: "Margin(%)", isCentered: true },
        { label: "Weightage", isCentered: true, width: "8%" },
        { label: "Start date", isCentered: true, width: "8%" },
        { label: "Expiry date", isCentered: true, width: "8%" },
        { label: "Status", isCentered: true, width: "6%" },
      ],

      brandMarginsModalHeaders: [
        { label: "SL.No", isCentered: true },
        { label: "Brand" },
        { label: "Category" },
        { label: "Product", width: "10%" },
        { label: "Warehouse", width: "10%" },
        { label: "Margin Type", isCentered: true },
        { label: "Margin(%)", isCentered: true },
        { label: "Weightage", isCentered: true, width: "8%" },
        { label: "Start date", isCentered: true, width: "8%" },
        { label: "Expiry date", isCentered: true, width: "8%" },
        { label: "Actions", isCentered: true, width: "6%" },
      ],

      formLabels: {
        brand: {
          label: "Brand",
          valuePath: "[0].value.name",
        },
        category: {
          label: "Category",
          valuePath: "[0].value.name",
        },
        product: {
          label: "Product",
          valuePath: "[0].value.name",
        },
        status: {
          label: "Status",
        },
        marginType: {
          label: "Margin Type",
        },
        createdAt: {
          label: "Date Range",
          type: "dateRange",
        },
      },
      // Form Labels Ends Here
    },
    kycDetails: {
      addressHeaders: [
        { label: "Address" },
        { label: "Landmark" },
        { label: "Town" },
        { label: "District" },
        { label: "State" },
        { label: "Pincode" },
      ],

      bankDetailsHeaders: [
        { label: "Sl No" },
        { label: "Bank" },
        { label: "Account No" },
        { label: "Account Holder Name" },
        { label: "Branch Name" },
        { label: "IFSC" },
        { label: "Is Active", isCenter: true },
      ],
    },
    accountStatement: {
      statusOptions: [
        { label: "All", value: "" },
        { label: "Committed", value: "Committed" },
        { label: "Pending", value: "Pending" },
      ],
      // status Options Ends

      referenceTypeOptions: [
        { label: "All", value: "" },
        { label: "GRN-INTAKE", value: "GRN-INTAKE" },
        { label: "GRN-PAYMENT", value: "GRN-PAYMENT" },
        { label: "GRN-DEBITNOTE-AUTO", value: "GRN-DEBITNOTE-AUTO" },
      ],
      // Reference Type Options Ends

      payoutTypeOptions: [
        { label: "All", value: "" },
        { label: "Credit", value: "Credit" },
        { label: "Debit", value: "Debit" },
      ],
      // Reference Type Options Ends

      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Reference Id" },
        { label: "Reference Type" },
        { label: "Payout Type	" },
        { label: "Opening Balance" },
        { label: "Amount" },
        { label: "Closing Balance" },
        { label: "Logged On" },
        { label: "Status" },
        { label: "Remarks", width: "25%" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        referenceId: "",
        payoutType: "",
        createdAt: [],
        referenceType: "",
        status: "",
      },
      // Filter Form Data ends Here
    },
    intakeSummary: {
      filterFormData: {
        createdAt: [
          sub(new Date(), {
            months: 3,
          }),
          new Date(),
        ],
        paymentStatus: "",
        toBankUtr: "",
        grnId: "",
      },
      // Filter Form Data Ends Here

      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Heres
      additionalTableDataConfig: [
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_createdBy",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
        {
          key: "modifiedBy",
          api: "user",
          loadingKey: "modifiedLoading",
          dataKey: "_modifiedBy",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // Additional Table

      summaryData: [
        {
          value: 0,
          key: "totalVendorAmount",
          color: "success",
          label: "Total Grn Value",
        },
        {
          value: 0,
          key: "totalPaidAmount",
          label: "Total Paid Value",
          color: "info",
        },
        {
          value: 0,
          key: "totalVendorRemainingAmount",
          color: "warning",
          label: "Total Remaining Value",
        },
      ],
      // Summary Card Data Ends Here
    },
    products: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 50,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 50,
      },
      filterFormData: {
        createdAt: [
          sub(new Date(), {
            months: 3,
          }),
          new Date(),
        ],
        productId: [],
      },
    },
    purchaseOrder: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      filterFormData: {
        createdAt: [
          sub(new Date(), {
            months: 3,
          }),
          new Date(),
        ],
      },
      status: "",
      poId: "",
    },
    grn: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      filterFormData: {
        createdAt: [
          sub(new Date(), {
            months: 3,
          }),
          new Date(),
        ],
      },
      status: "",
      grnId: "",
    },
  },
};

export const dashboardView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Vendor Dashboard",
    },
  ], // Breadcrumb Ends Here
  summaryCardOneData: [
    {
      label: "Total Vendor",
      value: 880,
      borderColor: "brdPink",
      bgColor: "cardBgPink",
      loading: false,
      icon: totalVendorsImg,
    },
    {
      label: "Total Brand",
      value: 534,
      borderColor: "brdOrange",
      bgColor: "cardBgOrange",
      loading: false,
      icon: totalBrandsImg,
    },
    {
      label: "Total Warehouse",
      value: 10,
      borderColor: "brdGreen",
      bgColor: "cardBgGreen",
      icon: totalWarehouseImg,
    },
    {
      label: "Total SKU's",
      value: 10,
      borderColor: "brdPurple",
      bgColor: "cardBgPurple",
      icon: totalSKUImg,
    },
  ], // Summary Card One Ends Here
  summaryCardTwoData: [
    {
      label: "Purchase Order",
      value: 165,
      loading: false,
      icon: purchaseOrderImg,
    },
    { label: "Invoiced", value: 110, loading: false, icon: invoicedImg },
    {
      label: "Pending Payments",
      value: 50000,
      type: "amount",
      loading: false,
      icon: pendingPaymentsImg,
    },
    {
      label: "Received Payments",
      value: 50000,
      type: "amount",
      loading: false,
      icon: receivedPaymentsImg,
    },
  ], // Summary Card Tow Ends Here
  vendorDetails: [
    {
      vendorDetails: {
        name: "Super Market Grocery Supplies",
        type: "Distributor",
        state: "Karnataka",
        district: "Bangalore",
        city: "Bangalore",
      },
      data: {
        type: "credit",
        value: 23445,
        icon: "bi bi-arrow-up-right",
        star: 4.4,
      },
    },
    {
      vendorDetails: {
        name: "Super Market Grocery Supplies",
        type: "Distributor",
        state: "Karnataka",
        district: "Bangalore",
        city: "Bangalore",
      },
      data: {
        type: "credit",
        value: 23445,
        icon: "bi bi-arrow-up-right",
        star: 4.4,
      },
    },
    {
      vendorDetails: {
        name: "Super Market Grocery Supplies",
        type: "Distributor",
        state: "Karnataka",
        district: "Bangalore",
        city: "Bangalore",
      },
      data: {
        type: "debit",
        value: 23445,
        icon: "bi bi-arrow-down-left",
        star: 4.2,
      },
    },
    {
      vendorDetails: {
        name: "Super Market Grocery Supplies",
        type: "Distributor",
        state: "Karnataka",
        district: "Bangalore",
        city: "Bangalore",
      },
      data: {
        type: "credit",
        value: 23445,
        icon: "bi bi-arrow-up-right",
        star: 4.1,
      },
    },
    {
      vendorDetails: {
        name: "Super Market Grocery Supplies",
        type: "Distributor",
        state: "Karnataka",
        district: "Bangalore",
        city: "Bangalore",
      },
      data: {
        type: "debit",
        value: 23445,
        icon: "bi bi-arrow-down-left",
        star: 4.1,
      },
    },
  ], // Vendor Details Ends Here
  poOrderTableHeader: [
    {
      label: "VENDOR",
      width: "40%",
    },
    {
      label: "STATUS",
    },
    {
      label: "PRICE",
    },
  ], // Po Order Table Header Ends Here
  poPaymentTableHeader: [
    {
      label: "VENDOR",
    },
    {
      label: "Payment Date",
    },
    {
      label: "PRICE",
    },
  ], // Po Payment Table Header
  alphabetSearchArray: [
    { label: "A" },
    { label: "B" },
    { label: "C" },
    { label: "D" },
    { label: "E" },
    { label: "F" },
    { label: "G" },
    { label: "H" },
    { label: "I" },
    { label: "J" },
    { label: "K" },
    { label: "L" },
    { label: "M" },
    { label: "N" },
    { label: "O" },
    { label: "P" },
    { label: "Q" },
    { label: "R" },
    { label: "S" },
    { label: "T" },
    { label: "U" },
    { label: "V" },
    { label: "W" },
    { label: "X" },
    { label: "Y" },
    { label: "Z" },
  ], // Alphabet Search Array
};

export default listView;
