import { VendorService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { set } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter"; // Import the new Filter component
import VendorAccRemarksModal from "./modals/VendorAccRemarksModal"; // Import the modal component

// Default Form Data
const filterFormData = {
  referenceId: "",
  payoutType: "",
  createdAt: [],
  referenceType: "",
  status: "",
};

// Default Table Header
const tableHeaders = [
  { label: "Sl No", width: "5%" },
  { label: "Reference Type", width: "15%" },
  { label: "Reference Id", width: "10%" },
  { label: "Payout Type", width: "8%" },
  { label: "Opening Balance", width: "10%" },
  { label: "Amount", width: "8%" },
  { label: "Closing Balance", width: "10%" },
  { label: "Logged On", width: "12%" },
  { label: "Status", width: "10%" },
  { label: "Remarks", width: "10%" },
];

const getData = async (params) => {
  const r = await VendorService.getStatementList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const prepareFilterParams = (pagination = {}, filter = {}) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: { vendorId: filter.vendorId },
  };

  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter?.status) {
    p.filter.status = filter.status;
  }

  if (filter?.payoutType) {
    p.filter.payoutType = filter.payoutType;
  }

  if (filter?.referenceType) {
    p.filter.referenceType = filter.referenceType;
  }
  if (filter?.referenceId) {
    p.filter.referenceId = { $regex: filter.referenceId, $options: "i" };
  }
  return p;
};

const AccountStatement = ({ vendorId }) => {
  const [loading, setLoading] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [list, setList] = useState([]);
  const [summaryData, setSummaryData] = useState({ loading: false, data: [] });
  const [remarksModal, setRemarksModal] = useState({
    show: false,
    remarks: "",
    referenceId: "",
  });

  const handleRowClick = (remarks, referenceId) => {
    setRemarksModal({ show: true, remarks, referenceId });
  };

  const handleCloseRemarksModal = () => {
    setRemarksModal({ show: false, remarks: "", referenceId: "" });
  };

  // Pagination Config
  const paginationConfigRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  useEffect(() => {
    if (vendorId) {
      filterDataRef.current = {
        ...filterDataRef.current,
        vendorId: vendorId,
      };
      applyFilter();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, vendorId]);

  const applyFilter = useCallback(
    (summaryRequired = true) => {
      loadCount();
      loadList();
      if (summaryRequired) {
        loadSummaryData();
      }
    },
    [loadCount, loadList, loadSummaryData]
  );

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;
    let m = await VendorService.getStatementCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;
    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const loadSummaryData = useCallback(async () => {
    setSummaryData({ loading: true, data: [] });

    let p = getFilterParams();
    delete p.status;
    delete p.payoutType;

    const r = await VendorService.getBalanceByvendorId(p);

    const summaryItems = [
      {
        name: "OPENING BALANCE",
        value: r?.resp?.openingBalance || 0,
        valueCss: "text-blue-200",
      },
      {
        name: "CLOSING BALANCE",
        value: r?.resp?.closingBalance || 0,
        valueCss: "text-green-100",
      },
      { name: "GRN-INTAKE", valueCss: "text-red-100" },
      { name: "GRN-PAYMENT", valueCss: "text-purple-100" },
      { name: "GRN-DEBITNOTE-AUTO", valueCss: "text-blue-100" },
      { name: "GRN-DEBITNOTE-MANUAL", valueCss: "text-pink-100" },
    ];

    const typeList = r.resp?.typeList || [];
    const updatedSummaryItems = summaryItems.map((item) => {
      if (item.name.startsWith("GRN")) {
        item.value = typeList.reduce(
          (sum, e) => sum + (e.name === item.name ? e.value : 0),
          0
        );
      }
      return item;
    });

    setSummaryData({ loading: false, data: updatedSummaryItems });
  }, [getFilterParams]);

  // To get List of All Lined Deals
  const loadList = useCallback(async () => {
    setLoading(true);
    let p = getFilterParams();
    let d = await getData(p);
    setList(d);
    setLoading(false);
  }, [getFilterParams]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadList();
    },
    [loadList]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current
    );
  }, []);

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = {
        ...filterDataRef.current,
        ...data.formData,
      };
      applyFilter();
    },
    [applyFilter]
  );

  const tableContainerStyle = {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
  };

  return (
    <>
      {/* Filter Form */}
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      {/* Balance statement */}
      <div className="row">
        {summaryData.data.length &&
          (summaryData.data || []).map((e, i) => (
            <div className="col-2" key={i}>
              <AppCard>
                <div className="fs-val-md fw-semibold text-center mb-2">
                  {e?.name}
                </div>
                <div
                  className={classNames(
                    "text-center fw-semibold fs-val-lg",
                    e.valueCss
                  )}
                >
                  <Amount value={e?.value} decimalPlace={2} />
                </div>
              </AppCard>
            </div>
          ))}
      </div>

      <AppCard>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        {/* Table Container */}
        <div style={tableContainerStyle} className="mb-3">
          <table className="table table-sm table-striped table-hover">
            <TableHeader data={tableHeaders} noBg={true} isSticky={true} />
            <tbody>
              {/* Loader */}
              {loading && (
                <TableSkeletonLoader rows={5} cols={tableHeaders.length} />
              )}

              {/*   No Data Found*/}
              {!loading && !list.length ? (
                <tr>
                  <td colSpan={tableHeaders.length}>
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {/* Table List */}
              {!loading &&
                (list || []).map((x, index) => (
                  <tr
                    key={index + x._id}
                    className="fs-val-md cursor-pointer"
                    onClick={() => handleRowClick(x?.remarks, x?.referenceId)}
                  >
                    <td>{paginationConfigRef.current.startSlNo + index}</td>
                    <td>{x?.referenceType}</td>
                    <td>{x?.referenceId}</td>
                    {/* Payout Type */}
                    <td>
                      <HighlightText
                        type={x?.payoutType == "Credit" ? "success" : "danger"}
                        template={2}
                      >
                        {x?.payoutType}
                      </HighlightText>
                    </td>
                    {/* Opening Balance */}
                    <td>
                      <Amount value={x?.openingBalance} />
                    </td>
                    {/* Amount */}
                    <td>
                      <Amount value={x?.amount} />
                    </td>
                    {/* Closing Balance */}
                    <td>
                      <Amount value={x?.closingBalance} />
                    </td>
                    {/* Last Updated */}
                    <td>
                      <DateFormatter
                        date={x?.lastUpdated}
                        format={"dd MMM yyyy"}
                      />
                      <span className="fs-val-sm text-muted ms-1">
                        <DateFormatter
                          date={x?.lastUpdated}
                          format={"hh:mm a"}
                        />
                      </span>
                    </td>
                    {/* Status */}
                    <td>{x?.status}</td>
                    {/* Remarks */}
                    <td>
                      <button
                        className="btn btn-link text-dark p-0 fs-val-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(x?.remarks, x?.referenceId);
                        }}
                      >
                        View Remarks
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Block */}
        <PaginationBlock
          paginationConfig={paginationConfigRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>

      {/* Remarks Modal */}
      <VendorAccRemarksModal
        show={remarksModal.show}
        handleClose={handleCloseRemarksModal}
        remarks={remarksModal.remarks}
        referenceId={remarksModal.referenceId}
      />
    </>
  );
};

export default memo(AccountStatement);
