import { GrnService } from "@sk/services";

import { Amount, PageLoader, TableHeader, Toaster } from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "65%",
  },
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Product ID", isCentered: true },
  { label: "Product Name" },
  { label: "MRP", isCentered: true },
  { label: "Qty", isCentered: true },
  { label: "Product Gst %", isCentered: true },
];

// const attachAdditionalDataConfig = [
//   {
//     key: "productId",
//     api: "product",
//     loadingKey: "productLoading",
//     dataKey: "_product",
//     filter: (ids) => ({
//       page: 1,
//       count: ids.length,
//       filter: { _id: { $in: ids } },
//       select: "name,tax",
//     }),
//   },
// ];

const ViewGrnProductGstModal = ({ show, closeModalCb, id }) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const GetPoGstDetails = useCallback(async () => {
    setLoading(true);
    const r = await GrnService.getGrnById(id);

    const d = r.resp || {};
    setData(d.productDetails);
    setLoading(false);

    if (!d._id) {
      Toaster.error("Failed to fetch data, please try again");
      closeModalCb();
    }
  }, [closeModalCb, id]);

  useEffect(() => {
    if (show && id) {
      GetPoGstDetails(id);
    }
  }, [show, id, GetPoGstDetails]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          <Offcanvas.Title>
            <div className="page-title"> Grn Gst Details : #{id} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <table className="table table-bordered table-sm">
                    <TableHeader data={headers} />
                    <tbody>
                      {/* No Data Found */}
                      {data.length == 0 && (
                        <tr>
                          <td
                            colSpan={headers.length}
                            className="fs-val-sm text-center"
                          >
                            No Data Found
                          </td>
                        </tr>
                      )}
                      {/* No  DAta */}
                      {data.length > 0 &&
                        data.map((item, i) => (
                          <tr key={i} className="fs-val-md">
                            <td className="text-center">{i + 1}</td>
                            <td className="text-center"> {item?.productId}</td>
                            <td> {item.name}</td>
                            <td className="text-center">
                              <Amount value={item.price.mrp} />
                            </td>
                            <td className="text-center">
                              {item.receivedQuantity}
                            </td>
                            <td className="text-center">{item.inputVat} %</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewGrnProductGstModal;
