import { GrnService } from "@sk/services";
import { set, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";
import { AppCard } from "@sk/uis";

const prepareFilterParams = (filter = {}, pagination = {}, sort = {}) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    select: "createdAt,invoiceDetails,status,productDetails,poIds,vendor",
    filter: {
      vendorId: filter.vendorId,
    },
  };

  if (Object.keys(sort).length > 0) {
    p.sort = sort;
  }

  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter.createdAt[1], { hours: 23, minutes: 59, seconds: 59 }),
    };
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }
  const grnId = filter?.grnId?.trim();
  const grnIdRegex = { $regex: grnId, $options: "gi" };
  if (grnId) {
    p.filter.$or = [{ _id: grnIdRegex }, { poIds: grnIdRegex }];
  }
  return p;
};

const defaultPaginationOpt = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const defaultFilterFormData = {
  createdAt: [
    sub(new Date(), {
      months: 3,
    }),
    new Date(),
  ],
  status: "",
  grnId: "",
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const getData = async (params) => {
  const r = await GrnService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  const r = await GrnService.getCount(params);
  return r.resp ?? 0;
};

const Grn = ({ vendorId }) => {
  const filterDataRef = useRef({ ...defaultFilterFormData, vendorId });
  const sortRef = useRef({ ...defaultSortOpt });
  const paginationRef = useRef({ ...defaultPaginationOpt });
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
  }, []);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const count = await getCount(p);
    paginationRef.current.totalRecords = count;
    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPaginationOpt };
    loadList();
    loadCount();
  }, [loadList, loadCount]);

  const init = useCallback(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      vendorId,
    };
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      vendorId,
    };
    init();
  }, [init, vendorId]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...data.formData };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <div>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>
      <AppCard>
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </div>
  );
};

export default Grn;
