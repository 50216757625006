import {
  BooleanCheckboxInput,
  HighlightText,
  KeyVal,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { each } from "lodash";
import { memo } from "react";
import { Accordion } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../../../constantService";

const contactValidationSchema = yup.object({
  contactDetailsForm: yup.object({
    name: yup.string().trim().required("Name is required"),
    isOwner: yup.boolean(),
    designation: yup.string().trim().required("Designation"),
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .email("Invalid email format"),
    mobile: yup
      .number()
      .nullable()
      .transform((v) => (isFinite(v) ? v : null))
      .min(1000000000, "Invalid Mobile Number")
      .max(9999999999, "Invalid Mobile Number")
      .label("Mobile")
      .required(),
  }),
});

const ContactDetails = () => {
  const {
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const contactDetailsList = useWatch({
    control,
    name: "contact",
  });

  const editIndex = useWatch({
    control,
    name: "contactDetailsForm.editIndex",
  });

  // const deleteContactDetails = (index) => {
  //   const k = [...contactDetailsList];
  //   k.splice(index, 1);
  //   setValue("contact", k);
  // };

  // ---------Edit Bank Details--------
  const editContactDetails = async (index) => {
    const d = contactDetailsList[index] || {};
    each(manageView.defaultContactDetailsFormData, (x, k) => {
      if (k == "editIndex") {
        setValue("contactDetailsForm.editIndex", index);
      } else {
        setValue("contactDetailsForm." + k, d[k]);
      }
    });

    setValue("contactDetailsForm.isOwner", d.isOwner == "true");
  };

  // ----------------------ADD FORM------------------------
  const addContactDetails = async () => {
    // attribute form validation
    let errMsg = "";
    try {
      await contactValidationSchema.validate({
        contactDetailsForm: getValues("contactDetailsForm"),
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let f = getValues("contactDetailsForm");

    const data = contactDetailsList?.slice() || [];
    // checking duplicate data in list
    let duplicate = data.find((k) => {
      return f.name == k?.name;
    });

    let ownerDuplicate = (data || []).find((k) => {
      return f.isOwner == k?.isOwner;
    });

    const formData = Object.assign({}, f);
    if (f.editIndex >= 0) {
      data.splice(f.editIndex, 1, formData);
    } else {
      if (duplicate?.name) {
        Toaster.error("Item already exists in List ");
        return;
      }
      if (ownerDuplicate && ownerDuplicate?.length) {
        Toaster.error("You can add only One Owner in contact details");
        return;
      }
      data.push(formData);
    }
    setValue("contact", data);
    Toaster.success(
      `Contact ${f.editIndex > -1 ? "updated" : "added"} successfully`
    );
    resetInputValue();
  };

  const resetInputValue = () => {
    each(manageView.defaultContactDetailsFormData, (x, k) => {
      setValue("contactDetailsForm." + k, x);
    });
    setValue("contactDetailsForm.isOwner", false);
  };

  return (
    <>
      {/* ATTRIBUTE  FORM FIELDS  */}

      {contactDetailsList?.length > 0 && (
        <div className="row mb-4">
          <Accordion>
            {contactDetailsList.map((x, i) => (
              <Accordion.Item eventKey={i} as={"div"} key={"item" + i}>
                <Accordion.Header as={"div"}>
                  <div className="row">
                    <div className="col-12 fs-val-lg">{x?.name}</div>
                    <div className="col-12">
                      <span className="fs-val-sm me-3">{x?.designation}</span>
                      <span className="fs-val-sm">{x?.mobile}</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-10">
                      <KeyVal label="Email">{x?.email}</KeyVal>
                      <KeyVal label="Is Owner">
                        <HighlightText status={x?.isOwner ? "Yes" : "No"} />
                      </KeyVal>
                    </div>
                    <div className="col-2">
                      <span
                        className="btn btn-sm fs-val-sm btn-outline-warning"
                        role="button"
                        tabIndex={0}
                        onClick={() => editContactDetails(i)}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      )}

      <div className="row">
        {/* Name */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter Name"
            label="Name"
            register={register}
            name="contactDetailsForm.name"
            error={errors?.contactDetailsForm?.name?.message}
            isMandatory={true}
          />
        </div>
        {/* Designation */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter designation"
            label="Designation"
            register={register}
            name="contactDetailsForm.designation"
            error={errors?.contactDetailsForm?.designation?.message}
            isMandatory={true}
          />
        </div>
        {/* Mobile Number */}
        <div className="col-12">
          <TextInput
            type="number"
            placeholder="Enter Mobile Number"
            label="Mobile Number"
            register={register}
            name="contactDetailsForm.mobile"
            error={errors?.contactDetailsForm?.mobile?.message}
            isMandatory={true}
          />
        </div>
        {/* Email Id */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter Email ID "
            label="Email"
            register={register}
            name="contactDetailsForm.email"
            error={errors?.contactDetailsForm?.email?.message}
            isMandatory={true}
          />
        </div>
        {/* Actions */}
        <div className="col-auto align-self-center">
          <Controller
            control={control}
            name="contactDetailsForm.isOwner"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                label="Is owner"
                name="contactDetailsForm.isOwner"
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>
        <div className="col-auto ms-auto text-end">
          {/* Add */}
          <button
            type="button"
            onClick={addContactDetails}
            className={classNames("btn btn-primary fs-val-sm bt-sm", {
              "btn-primary": !editIndex || editIndex == -1,
              "btn-success": editIndex >= 0,
            })}
          >
            {editIndex >= 0 ? "Update" : "Add"}
          </button>

          {/* Cancel */}
          {editIndex >= 0 && (
            <button
              type="button"
              onClick={resetInputValue}
              className="btn-outline-warning btn ms-2"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      <div>
        {/* <table className="table table-bordered table-sm">
          <TableHeader data={manageView.contactDetailsHeaders} />
          <tbody className="table-group-divider">
            {!contactDetailsList?.length ? (
              <tr>
                <td colSpan={manageView.contactDetailsHeaders.length}>
                  <div className="px-3">
                    <NoDataFound>No Data Found</NoDataFound>
                  </div>
                </td>
              </tr>
            ) : null}

            {(contactDetailsList || []).map((d, index) => (
              <tr key={index} className="fs-val-md">
                <td className="text-center p-2">{index + 1}</td>

                <td className="p-2">{d?.isOwner ? "Yes" : "No"}</td>

                <td className="p-2">{d?.name}</td>

                <td className="p-2">{d?.designation || "NA"}</td>

                <td className="p-2">{d?.email}</td>

                <td className="p-2">{d?.mobile}</td>

                <td className="text-center  p-2">
                  <span
                    className="shadow-sm p-0 px-1 bg-white rounded  me-3"
                    onClick={() => deleteContactDetails(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <i className="bi bi-x  text-danger cursor-pointer"></i>
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => editContactDetails(index)}
                  >
                    <i className="bi bi-pencil-square  text-primary cursor-pointer"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}
      </div>
    </>
  );
};

export default memo(ContactDetails);
