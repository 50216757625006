import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import DashboardVendor from "./pages/dashboad/DashboardVendor";
import ListVendor from "./pages/list/ListVendor";
import ManageVendor from "./pages/manage/ManageVendor";
import ViewVendor from "./pages/view/ViewVendor";

const rbac = {
  listVendor: ["ViewVendor"],
  manageVendor: ["EditVendor", "AddVendor"],
  viewVendor: ["ViewVendor"],
  dashboardVendor: ["EditVendor", "AddVendor"],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "vendor",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/vendor/list"></Navigate>,
          },
          {
            path: "list",
            element: (
              <ProtectedRoute roles={rbac.listVendor}>
                <ListVendor />
              </ProtectedRoute>
            ),
          },
          {
            path: "manage",
            element: (
              <ProtectedRoute roles={rbac.manageVendor}>
                <ManageVendor />
              </ProtectedRoute>
            ),
          },
          {
            path: "view",
            element: (
              <ProtectedRoute roles={rbac.viewVendor}>
                <ViewVendor />
              </ProtectedRoute>
            ),
          },
          {
            path: "dashboard",
            element: (
              <ProtectedRoute roles={rbac.dashboardVendor}>
                <DashboardVendor />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
