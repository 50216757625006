import {
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  AppCard,
} from "@sk/uis";

const headers = [
  { label: "SL.No", width: "3%" },
  { label: "Is Owner", isCentered: true, width: "10%" },
  { label: "Name", width: "30%" },
  { label: "Designation", width: "20%" },
  { label: "Mobile", width: "15%" },
  { label: "Email", width: "15%" },
];

const tableStyle = {
  width: "100%",
};

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const ContactDetails = ({ data }) => {
  return (
    <AppCard noShadow={true} title={`Contact Details (${data?.length})`}>
      <div style={tableContainerStyle}>
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {/* When Table is Loading */}
            {!data && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No Data */}
            {data && !data.length ? (
              <tr>
                <td colSpan={headers.length} className="p-1">
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When Data is available */}
            {data &&
              data.map((d, i) => (
                <tr key={i} className="fs-val-md">
                  <td className="p-2">{i + 1}</td>
                  <td className="p-2 text-center">
                    {d?.isOwner ? "Yes" : "No"}
                  </td>
                  <td className="p-2">{d?.name}</td>
                  <td className="p-2">{d?.designation || "NA"}</td>
                  <td className="p-2">{d?.mobile}</td>
                  <td className="p-2">{d?.email}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default ContactDetails;
