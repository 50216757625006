import * as yup from "yup";

const validationSchema = yup.object({
  activeTab: yup.string().default("kycDetails"),

  /*----------------Basic Details Validation-----------------*/

  name: yup.string().trim().required("Vendor Name Is Required"),
  entity: yup.string().trim().required("Nature Of Entity Is Required"),
  vendorType: yup.string().required("Vendor Type Is Required"),
  vendorCategory: yup.string().required("Vendor Category Is Required"),
  status: yup.boolean().default(true),
  isWarehouseVendor: yup.boolean().default(false),
  enablePurchaseToPos: yup.boolean().default(false),
  isOfficeAddressSame: yup.boolean(),
  warehouseDataId: yup.string().when("isWarehouseVendor", {
    is: (isWarehouseVendor) => isWarehouseVendor,
    then: (schema) => schema.label("Vendor Warehouse").required(),
  }),

  /*------------Basic Details Ends Here -----------------------*/

  // KYC

  /*------------Registered Office Address -----------------------*/
  addressLine1: yup
    .string()
    .trim()
    .when("activeTab", {
      is: (activeTab) => activeTab == "basicDetails",
      then: (schema) => schema.required("Line 1 is required"),
    }),
  addressLine2: yup
    .string()
    .trim()
    .when("activeTab", {
      is: (activeTab) => activeTab == "basicDetails",
      then: (schema) => schema.required("Line 2 is required"),
    }),
  addressLandmark: yup.string(),
  state: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("State is required"),
  }),
  district: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("District is required"),
  }),
  town: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("Town is required"),
  }),
  pincode: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "basicDetails",
      then: (schema) =>
        schema
          .label("Pincode")
          .min(100000, "Pincode  must be 6 digits")
          .max(999999, "Pincode  must be 6 digits")
          .required(),
    }),

  // /*------------Communication Address -----------------------*/
  communicationLine1: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("Line 1 is required"),
  }),
  communicationLine2: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("Line 2 is required"),
  }),
  communicationLandmark: yup.string(),
  communicationState: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("State is required"),
  }),
  communicationDistrict: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("District is required"),
  }),
  communicationCity: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "basicDetails",
    then: (schema) => schema.required("Town is required"),
  }),
  communicationPincode: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "basicDetails",
      then: (schema) =>
        schema
          .label("Pincode")
          .min(100000, "Pincode  must be 6 digits")
          .max(999999, "Pincode  must be 6 digits")
          .required(),
    }),

  // /*------------Contract Details -----------------------*/

  contractSerialNo: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.trim().label("Serial Number").required(),
  }),
  contractDateOfContract: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.label("Date Of Contract").required(),
  }),
  contractPeriodOfValidity: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.label("Period Of Validity").required(),
  }),
  contractRenewalDate: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.label("Renewal Date").required(),
  }),
  contractServicePeriod: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.label("Service Period").required(),
  }),
  contractEStampAmount: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "contractDetails",
      then: (schema) =>
        schema
          .label("E-Stamp Amount")
          .min(0, "E-Stamp Amount is Required")
          .max(9999999999, "Max Serial Number is 10 digits")
          .required("E-Stamp Amount is Required"),
    }),
  contractEStampDate: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) => schema.label("E-Stamp Date").required(),
  }),
  contractIsAutoRenewal: yup.boolean(),
  cancelChequeDoc: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "contractDetails",
    then: (schema) =>
      schema.min(1).label("Cancelled Cheque Document").required(),
  }),

  // /*------------Aadhar No/GST /PAN -----------------------*/
  aadharNo: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) =>
      schema
        .label("Aadhar Number")
        .matches(/^[0-9*]+$/, "Must be Numbers Only")
        .length(12, "Aadhar number should be 12 digits"),
  }),

  gst_no: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) =>
      schema
        .label("GST Number")
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Invalid GST format"
        )
        .required(),
  }),

  pan: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) =>
      schema
        .label("PAN Number")
        .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})$/, "Invalid PAN format")
        .required(),
  }),
  aadharDoc: yup.array(),
  panDoc: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) => schema.min(1).label("PAN Document").required(),
  }),
  gstDoc: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) => schema.min(1).label("GST Document").required(),
  }),

  // Credit /Payment details
  credit_limit: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "kycDetails",
      then: (schema) =>
        schema
          .label("Credit Limit")
          .min(1, "Credit Limit must be higher amount")
          .max(999999999999, "Credit Limit Maximum  15 digits")
          .required(),
    }),
  credit_days: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "kycDetails",
      then: (schema) =>
        schema
          .label("Credit days")
          .min(0)
          .max(999, "Credit days Maximum  3 digits")
          .required(),
    }),
  average_tat: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "kycDetails",
      then: (schema) =>
        schema
          .label("Average Delivery TAT  days")
          .min(0)
          .max(999, "Average Delivery TAT  Maximum  3 digits")
          .required(),
    }),
  preferred_payment_type: yup.string().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) => schema.label("Preferred Payment Type").required(),
  }),
  allowance_advance_payment: yup.boolean(),
  // Contract details

  // // Linked Warehouse
  // whIds: yup.array().when(["activeTab", "warehouseList"], {
  //   is: (activeTab, warehouseList) =>
  //     activeTab == "linkWarehouse" &&
  //     !warehouseList.filter((x) => x.selected).length,
  //   then: (schema) => schema.min(1).label("Linked Warehouse").required(),
  // }),

  margins: yup.array(),

  // Bank Details
  bankDetails: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) => schema.min(1).label("Bank Details").required(),
  }),

  // Contact Details
  contact: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "kycDetails",
    then: (schema) => schema.min(1).label("Contact Details").required(),
  }),
});
export default validationSchema;
