import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";

import { memo, useCallback, useRef, useState } from "react";
import ViewProductModal from "../modals/ViewProductModal";
import { GrnPoModal } from "@sk/features";
import { NavService } from "@sk/services";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "GRN ID", width: "10%" },
  { label: "Products", width: "10%" },
  { label: "Invoice Qty", width: "8%" }, // Added Invoice Qty column
  { label: "Received Qty", width: "8%" }, // Added Received Qty column
  { label: "Invoice Value", width: "10%" }, // Added Invoice Value column
  { label: "PO ID", width: "10%" },
  { label: "Status", width: "10%" },
  { label: "Inwarded On", width: "10%" },
];

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [grnModal, setGrnModal] = useState({
    show: false,
    poIds: [],
    grnId: "",
  }); // State for GrnPoModal

  const productModalRef = useRef({ data: [], grnId: "" });

  const openProductModal = useCallback((products, grnId) => {
    productModalRef.current.data = products;
    productModalRef.current.grnId = grnId;
    setShowProductModal(true);
  }, []);

  const closeProductModal = useCallback(() => {
    productModalRef.current.data = [];
    productModalRef.current.grnId = "";
    setShowProductModal(false);
  }, []);

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-2"
      />
      <div style={tableContainerStyle} className="mb-3">
        <table className="table table-sm">
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            noBg={true}
            isSticky={true}
          />

          {/* Table Body */}
          <tbody>
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No loading */}
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No GRN Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {/* When Not Loading and Data is there  */}
            {!loading &&
              data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  {/* Serial Number */}
                  <td>{paginationConfig.startSlNo + index}</td>
                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={(e) => {
                        e.stopPropagation();
                        NavService.openInNewTab("/purchase-order/grn/view", {
                          id: x._id,
                        });
                      }}
                    >
                      {x._id}
                    </BtnLink>
                  </td>
                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={(e) => {
                        e.stopPropagation();
                        openProductModal(x.productDetails, x._id);
                      }}
                    >
                      {x.productDetails.length} Products
                    </BtnLink>
                  </td>
                  <td>{x.totalInvoiceQty}</td> {/* Invoice Qty */}
                  <td>{x.totalReceivedQty}</td> {/* Received Qty */}
                  <td>
                    <Amount value={x.totalInvoiceValue} /> {/* Invoice Value */}
                  </td>
                  <td>
                    {x._poIds?.length > 0 ? (
                      <>
                        <BtnLink
                          className="fs-val-md"
                          onClick={(e) => {
                            e.stopPropagation();
                            NavService.openInNewTab("/purchase-order/view", {
                              id: x._poIds[0],
                            });
                          }}
                        >
                          {x._poIds[0]}
                        </BtnLink>
                        {x._poIds.length > 1 ? (
                          <button
                            className="btn btn-link p-0 text-start fs-val-xs"
                            onClick={() =>
                              setGrnModal({
                                show: true,
                                poIds: x._poIds,
                                grnId: x._id,
                              })
                            }
                          >
                            View{" "}
                            {x._poIds.length > 1
                              ? `+${x._poIds.length - 1}`
                              : null}
                          </button>
                        ) : null}
                      </>
                    ) : null}
                  </td>
                  <td>
                    <HighlightText type={x.statusColor} template={2}>
                      {x.status}
                    </HighlightText>
                  </td>
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />

      <ViewProductModal
        closeModalCb={closeProductModal}
        show={showProductModal}
        data={productModalRef.current.data}
        grnId={productModalRef.current.grnId}
      />

      <GrnPoModal
        show={grnModal.show}
        callback={setGrnModal}
        poids={grnModal.poIds}
        grnId={grnModal.grnId}
      />
    </>
  );
};

export default memo(Table);
