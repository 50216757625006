import { AuthService, UtilityService } from "@sk/services";
import { SelectInput, TextInput } from "@sk/uis";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import AdvanceFilterModal from "../modals/AdvanceFilterModal";
import { debounce } from "lodash";

// const categoryOptions = [
//   { label: "Choose", value: "" },
//   { label: "Electronics", value: "Electronics" },
//   { label: "FMCG", value: "FMCG" },
//   { label: "Service", value: "Service" },
// ];

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));
warehouseOptions.unshift({
  label: "All Warehouses",
  value: "",
});

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const FilterForm = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: {
      search: "",
      state: "",
      warehouse: AuthService.getDefaultWhId(),
      category: "",
      status: "",
    },
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [moreFilterModal, setMoreFilterModal] = useState({ show: false });

  const advFilterRef = useRef({});

  const debouncedCallback = useCallback(
    debounce((data) => {
      callback({ formData: data });
    }, 800),
    [callback]
  );

  const onSearch = () => {
    debouncedCallback(getValues());
  };

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await UtilityService.getStateList();
        const states = response.resp.map((state) => ({
          label: state.name,
          value: state.name,
        }));
        setStateOptions([{ label: "All States", value: "" }, ...states]);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, []);

  const moreFilterCb = useCallback(
    (data) => {
      if (data.action === "apply" || data.action === "reset") {
        advFilterRef.current = data.formData;
        callback({ formData: { ...getValues(), ...advFilterRef.current } });
      }
      setMoreFilterModal({ show: false });
    },
    [callback, getValues]
  );

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col">
            <TextInput
              name="search"
              type="text"
              register={register}
              label="Search"
              placeholder="Search by ID/Name/Email/Phone/GST"
              callback={onSearch}
              gap={1}
            />
          </div>

          <div className="col">
            <SelectInput
              label="State"
              register={register}
              name="state"
              callback={onFilterChange}
              isMandatory={false}
              options={stateOptions}
              gap={1}
            />
          </div>

          <div className="col">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
              gap={1}
            />
          </div>

          <div className="col-2">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
              gap={1}
            />
          </div>

          <div className="col align-self-end">
            <button
              className="btn btn-outline-primary btn-sm mb-2"
              type="button"
              onClick={() => setMoreFilterModal({ show: true })}
            >
              More Filters
            </button>
          </div>
        </div>
      </form>

      <AdvanceFilterModal
        show={moreFilterModal.show}
        callback={moreFilterCb}
        formData={advFilterRef.current}
      />
    </>
  );
};

export default FilterForm;
