// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1wAfKGuounAzA3LjgR1uBw\\=\\={border-top:2px solid #fb869f}._1RsESJxw0xUhWhCtB7\\+zMg\\=\\={background-color:#ffe2e5}.P6vHh3gZKMauSoBKX0aGzA\\=\\={border-top:2px solid #ffd3bb}.lxBpzDkaorZD4yVLVUbAuQ\\=\\={background-color:#fff4de}.-cWI3xMrI5X6kUz26\\+pTtA\\=\\={border-top:2px solid #71e486}.NWnfPCh7H3n3qEib731E\\+g\\=\\={background-color:#dcfce7}.OL2NArtqNooETVS\\+FAkUGw\\=\\={border-top:2px solid #d0a4ff}.SfabP\\+HoKbkmGa3mQMPY3w\\=\\={background-color:#f3e8ff}.GWeYcaPFCuG0FDdEPk9J\\+Q\\=\\={overscroll-behavior:contain}", "",{"version":3,"sources":["webpack://./src/pages/dashboad/dashboard.module.scss"],"names":[],"mappings":"AAAA,6BACE,4BAAA,CAEF,8BACE,wBAAA,CAGF,4BACE,4BAAA,CAGF,4BACE,wBAAA,CAGF,6BACE,4BAAA,CAGF,6BACE,wBAAA,CAGF,6BACE,4BAAA,CAGF,6BACE,wBAAA,CAGF,6BACE,2BAAA","sourcesContent":[".brdPink {\n  border-top: 2px solid #fb869f;\n}\n.cardBgPink {\n  background-color: #ffe2e5;\n}\n\n.brdOrange {\n  border-top: 2px solid #ffd3bb;\n}\n\n.cardBgOrange {\n  background-color: #fff4de;\n}\n\n.brdGreen {\n  border-top: 2px solid #71e486;\n}\n\n.cardBgGreen {\n  background-color: #dcfce7;\n}\n\n.brdPurple {\n  border-top: 2px solid #d0a4ff;\n}\n\n.cardBgPurple {\n  background-color: #f3e8ff;\n}\n\n.scroll-contain {\n  overscroll-behavior: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brdPink": "_1wAfKGuounAzA3LjgR1uBw==",
	"cardBgPink": "_1RsESJxw0xUhWhCtB7+zMg==",
	"brdOrange": "P6vHh3gZKMauSoBKX0aGzA==",
	"cardBgOrange": "lxBpzDkaorZD4yVLVUbAuQ==",
	"brdGreen": "-cWI3xMrI5X6kUz26+pTtA==",
	"cardBgGreen": "NWnfPCh7H3n3qEib731E+g==",
	"brdPurple": "OL2NArtqNooETVS+FAkUGw==",
	"cardBgPurple": "SfabP+HoKbkmGa3mQMPY3w==",
	"scroll-contain": "GWeYcaPFCuG0FDdEPk9J+Q=="
};
export default ___CSS_LOADER_EXPORT___;
