import { yupResolver } from "@hookform/resolvers/yup";
import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import {
  AjaxService,
  NavService,
  UtilityService,
  VendorService,
} from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  TabContent,
  Tabs,
  Toaster,
} from "@sk/uis";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { manageView } from "../constantService";
import validationSchema from "./ManageVendorValidationSchema";
import BasicDetails from "./components/BasicDetails";
import { BasicInformation, ContractDetails } from "./components/tabs";
import KycDetails from "./components/tabs/KycDetails";
import LinkWarehouse from "./components/tabs/LinkWarehouse";
import BrandMargin from "./components/tabs/components/BrandMargin";

const defaultBreadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Vendor List",
    link: "/vendor/list",
  },
  {
    name: "Add Vendor",
  },
];

const prepareParams = (d, id) => {
  let docs = [];
  let type = ["aadharDoc", "gstDoc", "panDoc", "cancelChequeDoc"];

  type.forEach((e) => {
    if (d[e] && d[e]?.length) {
      d[e].forEach((k) => {
        docs.push(k);
      });
    }
  });

  // Margins
  let mar = (d.margins || []).map((e) => {
    let dt = [
      "warehouse",
      "_product",
      "_category",
      "_brand",
      "categoryLoading",
      "productLoading",
      "brandLoading",
    ];
    dt.forEach((e) => {
      delete e[dt];
    });
    e.end_date = Array.isArray(e.end_date) ? e.end_date[0] : e.end_date;
    e.start_date = Array.isArray(e.start_date) ? e.start_date[0] : e.start_date;
    e.weightage = e.weightage || 0;
    return e;
  });

  let p = {
    aadharNo: d.aadharNo,
    address: {
      line1: d?.addressLine1 || "",
      line2: d?.addressLine2 || "",
      landmark: d?.addressLandmark || "",
    },
    allowance_advance_payment: d?.allowance_advance_payment || false,
    average_tat: d?.average_tat || 0,
    bankDetails: d?.bankDetails.length
      ? d?.bankDetails.map((e) => {
          return { ...e, accountNumber: e.accountNumber.toString() };
        })
      : [],
    city: d?.town || "",
    communication: {
      city: d?.communicationCity || "",
      district: d?.communicationDistrict || "",
      landmark: d?.communicationLandmark || "",
      line1: d?.communicationLine1 || "",
      line2: d?.communicationLine2 || "",
      pincode: d?.communicationPincode || "",
      state: d?.communicationState || "",
    },
    contact: d?.contact?.length
      ? d.contact.map((e) => {
          return { ...e, isOwner: e.isOwner == "true" };
        })
      : [],
    contract: {
      dateOfContract: d?.contractDateOfContract || "",
      eStampAmount: d?.contractEStampAmount || 0,
      eStampDate: d?.contractEStampDate || "",
      periodOfValidity: d?.contractPeriodOfValidity || "",
      renewalDate: d?.contractRenewalDate || "",
      serialNo: d?.contractSerialNo || "",
      servicePeriod: d?.contractServicePeriod || "",
    },
    credit_days: d?.credit_days || 0,
    credit_limit: d?.credit_limit || 0,
    district: d?.district || "",
    docs: docs,
    enablePurchaseToPos: d?.enablePurchaseToPos || false,
    entity: d?.entity || "",
    // geolocation : {type: "Point", coordinates: [12.9715987, 77.5945627]},
    gst_no: d?.gst_no || "",
    isOfficeAddressSame: d?.isOfficeAddressSame,
    isWarehouseVendor: d?.isWarehouseVendor || false,
    // margins: mar || [],
    msmeDoc: d?.msmeDoc || [],
    name: d?.name || "",
    pan: d?.pan || "",
    pincode: d?.pincode,
    preferred_payment_type: d?.preferred_payment_type || "",
    state: d?.state || "",
    status: d?.status == "true" || d.status ? "Active" : "Inactive",
    vendorCategory: d?.vendorCategory,
    vendorType: d?.vendorType,
    warehouseData: { id: d?.warehouseDataId || "" },
  };

  if (id) {
    p["_id"] = id;
  }

  // When WarehouseList is There
  p.whIds = d.whIds;

  return p;
};

const ManageVendor = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: manageView.defaultFormValue,
  });

  const [activeTab, setActiveTab] = useState({
    key: "basicDetails",
  });

  const [breadcrumbs, setBreadcrumbs] = useState([...defaultBreadcrumbs]);

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [searchParams] = useSearchParams();

  const [display, setDisplay] = useState("loading");

  const [stateOptions, setStateOptions] = useState([]);

  const [marginDetails, setMarginDetails] = useState({});

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const query = useFetchUrlQueryString(searchParams);

  const navigate = useNavigate();

  const formValidation = (d) => {
    if (!d.bankDetails.length) {
      return "Please Add Bank Details";
    } else if (!d.contact || !d.contact.length) {
      return "Please Add Contact Details";
    } else if (!d.whIds || !d.whIds.length) {
      return "Please Add Linked Warehouse";
    } else {
      return "";
    }
  };

  const onSubmit = async (data) => {
    let errMsg = "";
    let err = formValidation(data);
    if (err) {
      Toaster.error(err);
      return;
    }

    for (let t of manageView.tabs) {
      try {
        await validationSchema.validate({
          ...data,
          activeTab: t.key,
        });
        errMsg = "";
      } catch (error) {
        errMsg = error.message || "";
      }
      if (errMsg) {
        break;
      }
    }
    if (!errMsg) {
      errMsg = formValidation(data);
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setBusyLoader({
      msg: "please,wait submitting your response",
      loading: true,
    });
    const params = prepareParams(data, query.id || "");
    let r;
    let msg = "";
    if (query.id) {
      r = await VendorService.updateVendor(query.id, params);
      msg = "Vendor Details has been Updated Successfully";
    } else {
      msg = "Vendor Details has been  Added Successfully";
      r = await VendorService.createVendor(params);
    }

    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success(msg);
      NavService.to(navigate, "/Vendor/list");
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0] || "Please Try Again");
    }
  };

  const tabCb = (data) => {
    methods.setValue("activeTab", data.value.key);
    setActiveTab({ ...data.value });
  };

  useEffect(() => {
    loadStateList();

    const loadVendorDetails = async (id) => {
      let m = await VendorService.getVendor(id);
      if (m?.resp?._id) {
        const r = m?.resp;

        let tmp = [];
        setAdditionalData(
          r.margins,
          manageView.attachBrandMarginAdditionalDataConfig,
          (x) => {
            tmp.push(x);
            if (
              tmp.length ==
              manageView.attachBrandMarginAdditionalDataConfig.length
            ) {
              const a = [...attachAllData(r.margins, tmp)];
              setMarginDetails((v) => [...a]);
            }
          }
        );
        setMarginDetails(r.margins);

        // Form Auto fill
        const formKeys = validationSchema.getDefault();
        const ignoreFormKeys = [];
        Object.keys(formKeys).forEach((key) => {
          if (ignoreFormKeys.indexOf(key) === -1) {
            if (key === "mainImages") {
              methods.setValue(
                key,
                r[key].map((img) => ({ asset: img }))
              );
            } else if (key === "iconFileImage") {
              methods.setValue(
                key,
                r?.images?.map((img) => ({ asset: img.image }))
              );
            } else {
              methods.setValue(key, r[key]);
            }
          }
        });

        // auto fill contract, address and other info
        loadVendorFormDetails(r);

        loadDocuments(r);
        setDisplay("form");
      } else {
        setDisplay("notFound");
      }
    };
    if (query?.id) {
      setDisplay("loading");
      setBreadcrumbs(
        produce((draft) => {
          draft[2].name = "Edit Vendor";
        })
      );

      loadVendorDetails(query.id);
    } else {
      setDisplay("form");
    }
  }, [
    breadcrumbs,
    attachAllData,
    loadDocuments,
    loadVendorFormDetails,
    methods,
    query.id,
    setAdditionalData,
  ]);

  const loadVendorFormDetails = useCallback(
    (r) => {
      methods.setValue("warehouseDataId", r?.warehouseData?.id || "");
      methods.setValue("town", r?.city || "");
      methods.setValue("bankDetails", r?.bankDetails || "");
      methods.setValue("contact", r?.contact || "");

      // AddressDetails
      methods.setValue("addressLine1", r?.address?.line1 || "");
      methods.setValue("addressLine2", r?.address?.line2 || "");
      methods.setValue("addressLandmark", r?.address?.landmark || "");

      // Communication details
      methods.setValue("communicationLine1", r?.communication?.line1 || "");
      methods.setValue("communicationLine2", r?.communication?.line2 || "");
      methods.setValue(
        "communicationLandmark",
        r.communication?.landmark || ""
      );
      methods.setValue("communicationState", r?.communication?.state || "");
      methods.setValue(
        "communicationDistrict",
        r.communication?.district || ""
      );
      methods.setValue("communicationCity", r?.communication?.city || "");
      methods.setValue("communicationPincode", r?.communication?.pincode || "");

      // Contract Details
      methods.setValue("contractSerialNo", r?.contract?.serialNo || "");
      methods.setValue(
        "contractDateOfContract",
        r?.contract?.dateOfContract || ""
      );
      methods.setValue(
        "contractPeriodOfValidity",
        r?.contract?.periodOfValidity || ""
      );
      methods.setValue("contractRenewalDate", r?.contract?.renewalDate || "");
      methods.setValue(
        "contractServicePeriod",
        r?.contract?.servicePeriod || ""
      );
      methods.setValue("contractEStampAmount", r?.contract?.eStampAmount || "");
      methods.setValue("contractEStampDate", r?.contract?.eStampDate || "");
      // methods.setValue("contractIsAutoRenewal", false);
      methods.setValue("isOfficeAddressSame", r.isOfficeAddressSame);

      methods.setValue("whIds", r.whIds || []);

      // Status
      methods.setValue("status", r.status == "Active");
    },
    [methods]
  );

  const loadDocuments = useCallback(
    (r) => {
      let f = [
        { name: "Aadhar Card", key: "aadharDoc" },
        { name: "Pan Card", key: "panDoc" },
        { name: "GST Document", key: "gstDoc" },
        { name: "Cancel Cheque", key: "cancelChequeDoc" },
      ];
      f.forEach((k) => {
        let data = (r.docs || []).filter((e) => {
          return e.name == k.name;
        });
        methods.setValue(k.key, data);
      });
      methods.setValue("msmeDoc", r?.msmeDoc || []);
    },
    [methods]
  );

  const loadStateList = async () => {
    let r = await UtilityService.getStateList();
    let opt = (r.resp || []).map((e) => {
      return { label: e.name, value: e };
    });
    setStateOptions(opt);
  };

  return (
    <>
      {display == "loading" ? <PageLoader animation="border" /> : null}
      {display === "form" ? (
        <>
          <PageInfo
            title={query?.id ? "Edit Vendor" : "Add Vendor"}
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />

          <FormProvider {...methods}>
            <AppCard>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div className="row">
                  {/* Basic Details */}
                  <div className="col-12">
                    <BasicDetails />
                  </div>

                  <div className="col-12 mt-4">
                    <Tabs
                      data={manageView.tabs}
                      callback={tabCb}
                      activeTab={activeTab}
                    />

                    <TabContent>
                      <div className="p-4">
                        {/* Map Details
                        {activeTab.key === "kycDetails" && <MapDetails />} */}

                        {/* KYC Details */}
                        {activeTab.key === "kycDetails" && (
                          <KycDetails stateOptions={stateOptions} />
                        )}

                        {activeTab.key === "basicDetails" && (
                          <BasicInformation />
                        )}

                        {/* Contact Details */}
                        {activeTab.key === "contractDetails" && (
                          <ContractDetails />
                        )}

                        {/* Brand Margin */}
                        {activeTab.key === "brandsMargin" && (
                          <BrandMargin margin={marginDetails} />
                        )}

                        {/* Link Warehouse */}
                        {activeTab.key === "linkWarehouse" && <LinkWarehouse />}
                      </div>
                    </TabContent>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="text-end py-3">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </AppCard>
          </FormProvider>
        </>
      ) : null}
      {display === "notFound" && <NoDataFound>No Data Found</NoDataFound>}
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default ManageVendor;
