import { GrnService } from "@sk/services";

import { useAttachAdditionalData } from "@sk/hooks";

import { AppCard, SummaryCard } from "@sk/uis";
import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { viewVendor } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultFilterFormData = viewVendor.tabData.intakeSummary.filterFormData;

const defaultPaginationOpt = viewVendor.tabData.intakeSummary.pagination;

const additionalTableDataConfig =
  viewVendor.tabData.intakeSummary.additionalTableDataConfig;

const getData = async (id, params) => {
  const r = await GrnService.getVendorPurchaseSummary(id, params);
  return Array.isArray(r.resp?.result) ? r.resp : {};
};

const getCount = async (id, params = {}) => {
  const p = { ...params, type: "count" };

  const r = await GrnService.getVendorPurchaseSummary(id, p);

  return r?.resp?.result?.[0] ? r?.resp?.result?.[0]?.totalCount : 0;
};

const prepareFilterParams = (filter = {}, pagination = {}) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      createdAt: {
        $gte: filter.createdAt[0],
        $lte: filter.createdAt[1],
      },
    },
  };

  if (filter.paymentStatus) {
    p.filter.paymentStatus = filter.paymentStatus;
  }

  const toBankUtr = filter.toBankUtr.trim();
  if (toBankUtr) {
    p.filter["vendorPaymentDetails.toBankUtr"] = toBankUtr;
  }

  const grnId = filter.grnId.trim();
  const grnRegex = { $regex: grnId, $options: "gi" };
  if (grnId) {
    p.filter.$or = [{ _id: grnRegex }, { "productDetails.poId": grnRegex }];
  }
  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const defaultSummaryCardData = viewVendor.tabData.intakeSummary.summaryData;

const IntakeSummary = ({ vendorId }) => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [summaryCard, setSummaryCard] = useState([...defaultSummaryCardData]);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const filterFromRef = useRef({ ...defaultFilterFormData });

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(filterFromRef.current, paginationRef.current);
  }, []);

  const loadList = useCallback(
    async (isLoadMore = false) => {
      setLoadingData(true);

      const p = getFilterParams();
      const d = await getData(vendorId, p);

      // Setting Summary Card Data
      setSummaryCard(
        produce((draft) => {
          defaultSummaryCardData.forEach((item, i) => {
            draft[i].value = d[item.key];
          });
        })
      );

      setData(
        produce((draft) => {
          if (!isLoadMore) {
            draft.length = 0;
          }
          draft.push(...d.result);
        })
      );

      setLoadingData(false);
    },
    [getFilterParams, vendorId]
  );

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);

    const p = getFilterParams();

    const count = await getCount(vendorId, p);

    paginationRef.current.totalRecords = count;

    setLoadingTotalRecords(false);
  }, [vendorId, getFilterParams]);

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPaginationOpt };

    loadList();

    loadCount();
  }, [loadList, loadCount]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  useEffect(() => {
    init();
  }, [init]);

  const filterFormCb = useCallback(
    (data) => {
      filterFromRef.current = {
        ...filterFromRef.current,
        ...data.formData,
      };
      applyFilter();
    },
    [applyFilter]
  );

  const tableCb = useCallback(
    (data) => {
      if (data.action === "loadMore") {
        paginationRef.current.activePage++;
        loadList(true);
      }
    },
    [loadList]
  );

  return (
    <div>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      <div className="row">
        {summaryCard.map((card, index) => (
          <div className="col-auto" key={index}>
            <SummaryCard
              value={card.value}
              title={card.label}
              loading={loadingData}
              valueColor={card.color}
              img={card.img}
              template={3}
              isValueAmt={true}
            />
          </div>
        ))}
      </div>

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
        callback={tableCb}
      />
    </div>
  );
};

export default IntakeSummary;
