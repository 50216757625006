import { AjaxService, AuthService, DigitalPartnerService } from "@sk/services";
import {
  FileUpload,
  ImgPreviewModal,
  ImgRender,
  InputErrorMsg,
  SelectInput,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { manageView } from "../../../constantService";
import BankDetails from "./components/BankDetails";

const KycDetails = () => {
  const {
    register,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const imgPreviewDataRef = useRef([]);

  const getImageDocObject = (name, e) => {
    return {
      inputGroupCode: "Address Proof Details",
      name: name,
      type: e.resp.type,
      filename: e.resp.originalName,
      value: e.asset,
    };
  };

  const closeImgPreviewModal = () => {
    imgPreviewDataRef.current = [];
    setShowImgPreviewModal(false);
  };

  const viewImgPreviewModal = (id) => {
    let d = [{ id: id }];
    imgPreviewDataRef.current = d;
    setShowImgPreviewModal(true);
  };

  const aadharFrontCb = async (e) => {
    const d = getValues("aadharDoc");
    d[0] = getImageDocObject("Aadhar Card", e);
    setValue("aadharDoc", d);
  };

  const aadharBackCb = async (e) => {
    const d = getValues("aadharDoc");
    d[1] = getImageDocObject("Aadhar Card", e);
    setValue("aadharDoc", d);
  };

  const panDocCb = async (e) => {
    setValue("panDoc", [getImageDocObject("Pan Card", e)]);
  };

  const gstDocCb = async (e) => {
    setValue("gstDoc", [getImageDocObject("GST Document", e)]);
  };

  const removeUploadedDoc = async (key, index) => {
    let d = getValues(key);
    d.splice(index, 1, null);
    setValue(key, d);
    await trigger(key);
  };

  // const downloadDoc = async (assetId) => {
  //   let url = appConfigs.ASSET + "/" + assetId;
  //   window.open(url, "_blank");
  // };

  const verifyGst = async () => {
    let val = getValues("gst_no");
    let emp = AuthService.getLoggedInEmp();
    if (!val) {
      Toaster.error("Please Enter GST");
      return;
    } else {
      let p = {
        documentId: val,
        refId: emp.employee || "",
        taskType: "Verification",
        type: "Gst",
      };
      let r = await DigitalPartnerService.kycDocumentValidation(p);
      if (r.statusCode == 200) {
        Toaster.success("Entered GST is Valid ,Please continue the process");
      } else {
        let error = AjaxService.parseError(r.resp);
        Toaster.error(error?.msg.length ? error?.msg[0] : "Invalid GST");
      }
    }
  };

  const [aadharDoc, panDoc, gstDoc] = useWatch({
    name: ["aadharDoc", "panDoc", "gstDoc"],
    control,
  });

  return (
    <>
      <div className="row">
        {/* Aadhar, pan ,gst Details */}
        <div className="col-4 gx-5">
          <div className="col-12">
            <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
              Aadhar / PAN /GST Details
            </div>
          </div>
          {/* Form */}
          <div className="row">
            {/* Aadhaar */}
            <div className="col-12">
              <TextInput
                type="text"
                label="Aadhaar Card No."
                placeholder="Aadhaar Number"
                register={register}
                name="aadharNo"
                error={errors?.aadharNo?.message}
                isMandatory={false}
              />
            </div>

            {/* Aadhar Front Image */}
            <div className="col-6">
              <div className="row">
                {/* Image rendering */}
                <div className="col-auto">
                  {!aadharDoc?.[0] ? (
                    <div className="upload-placeholder-t1">
                      <i className="bi bi-image"></i>
                    </div>
                  ) : (
                    <div className="uploaded-t1-img-cnt">
                      <i
                        tabIndex={-1}
                        role="button"
                        className="bi bi-x-circle close"
                        onClick={() => {
                          removeUploadedDoc("aadharDoc", 0);
                        }}
                      ></i>
                      <div
                        role="button"
                        onClick={() =>
                          viewImgPreviewModal(aadharDoc?.[0]?.value)
                        }
                        tabIndex={-1}
                      >
                        <ImgRender
                          assetId={
                            aadharDoc.length ? aadharDoc?.[0]?.["value"] : ""
                          }
                          width="100"
                          height="100"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Action button  */}
                <div className="col col-ms-auto align-self-end">
                  <div>
                    <FileUpload
                      template={3}
                      callback={aadharFrontCb}
                      maxSize={10}
                    />
                    <span className="col-form-label fs-val-md">
                      Aadhar Front <span className="text-danger"> * </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Aadhar Back Image */}
            <div className="col-6">
              <div className="row">
                {/* Image rendering */}
                <div className="col-auto">
                  {!aadharDoc[1] ? (
                    <div className="upload-placeholder-t1">
                      <i className="bi bi-image"></i>
                    </div>
                  ) : (
                    <div className="uploaded-t1-img-cnt">
                      <i
                        tabIndex={-1}
                        role="button"
                        className="bi bi-x-circle close"
                        onClick={() => {
                          removeUploadedDoc("aadharDoc", 1);
                        }}
                      ></i>
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() =>
                          viewImgPreviewModal(aadharDoc?.[1]?.value)
                        }
                      >
                        <ImgRender
                          assetId={
                            aadharDoc.length ? aadharDoc?.[1]?.["value"] : ""
                          }
                          width="100"
                          height="100"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Action button  */}
                <div className="col col-ms-auto align-self-end">
                  <div>
                    <FileUpload
                      template={3}
                      callback={aadharBackCb}
                      maxSize={10}
                    />
                    <label className="col-form-label fs-val-md">
                      Aadhar Back <span className="text-danger"> * </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {errors?.aadharDoc?.message && (
              <div className="col-12">
                <InputErrorMsg msg={errors?.aadharDoc?.message} />
              </div>
            )}

            {/* PAN Card No. */}
            <div className="col-12 mt-2">
              <TextInput
                type="text"
                label="PAN Card No."
                placeholder="PAN Card Number"
                register={register}
                name="pan"
                error={errors?.pan?.message}
                isMandatory={true}
              />
            </div>

            {/* Pan Card */}
            <div className="col-6">
              <div className="row">
                {/* Image rendering */}
                <div className="col-auto">
                  {!panDoc?.[0] ? (
                    <div className="upload-placeholder-t1">
                      <i className="bi bi-image"></i>
                    </div>
                  ) : (
                    <div className="uploaded-t1-img-cnt">
                      <i
                        tabIndex={-1}
                        role="button"
                        className="bi bi-x-circle close"
                        onClick={() => {
                          removeUploadedDoc("panDoc", 0);
                        }}
                      ></i>
                      <dir
                        role="button"
                        onClick={() => viewImgPreviewModal(panDoc?.[0]?.value)}
                        tabIndex={-1}
                      >
                        <ImgRender
                          assetId={panDoc.length ? panDoc?.[0]?.["value"] : ""}
                          width="100"
                          height="100"
                        />
                      </dir>
                    </div>
                  )}
                </div>
                {/* Action button  */}
                <div className="col col-ms-auto align-self-end">
                  <div>
                    <FileUpload template={3} callback={panDocCb} maxSize={10} />
                    <span className="col-form-label fs-val-md">
                      PAN Card <span className="text-danger"> * </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* gst_no */}
            <div className="col-12 mt-2">
              <TextInput
                type="text"
                label="GST No."
                placeholder="GST Number"
                register={register}
                name="gst_no"
                error={errors?.gst_no?.message}
                isMandatory={true}
              />
            </div>
            {/* GST Upload */}
            <div className="col-8">
              <div className="row">
                {/* Image rendering */}
                <div className="col-auto">
                  {!gstDoc?.[0] ? (
                    <div className="upload-placeholder-t1">
                      <i className="bi bi-image"></i>
                    </div>
                  ) : (
                    <div className="uploaded-t1-img-cnt">
                      <i
                        tabIndex={-1}
                        role="button"
                        className="bi bi-x-circle close"
                        onClick={() => {
                          removeUploadedDoc("gstDoc", 0);
                        }}
                      ></i>
                      <div
                        role="button"
                        onClick={() => viewImgPreviewModal(gstDoc?.[0]?.value)}
                        tabIndex={-1}
                      >
                        <ImgRender
                          assetId={gstDoc.length ? gstDoc?.[0]?.["value"] : ""}
                          width="100"
                          height="100"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Action button  */}
                <div className="col col-ms-auto align-self-end">
                  <div>
                    <FileUpload template={3} callback={gstDocCb} maxSize={10} />
                    <span className="col-form-label fs-val-md">
                      Gst Certificate <span className="text-danger"> * </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4  align-self-start text-end">
              <span
                role="button"
                tabIndex={-1}
                className="btn  btn-primary fs-val-md"
                onClick={verifyGst}
                type="button"
              >
                Validate
              </span>
            </div>
          </div>
        </div>
        {/* Bank Details */}
        <div className="col-4 gx-5">
          <div className="col-12">
            <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
              Bank Details
            </div>
          </div>
          <BankDetails />
        </div>

        {/* Credit / Payment Details */}
        <div className="col-4 gx-5">
          <div className="col-12">
            <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
              Credit /Payment Details
            </div>
          </div>
          <div className="row">
            {/* Credit Limit  */}
            <div className="col-12">
              <TextInput
                type="number"
                label="Credit Limit"
                placeholder="Credit Limit"
                register={register}
                name="credit_limit"
                error={errors?.credit_limit?.message}
                isMandatory={true}
              />
            </div>

            {/* Credit Days  */}
            <div className="col-12">
              <TextInput
                type="number"
                label="Credit Days"
                placeholder="Credit Days"
                register={register}
                name="credit_days"
                error={errors?.credit_days?.message}
                isMandatory={true}
              />
            </div>

            {/* Average Delivery TAT (Days)  */}
            <div className="col-12">
              <TextInput
                type="number"
                label="Average Delivery TAT (Days)"
                placeholder="Average Delivery TAT (Days)"
                register={register}
                name="average_tat"
                error={errors?.average_tat?.message}
                isMandatory={true}
              />
            </div>

            {/* Preferred Payment  */}
            <div className="col-12">
              <SelectInput
                type="text"
                label="Preferred Payment"
                register={register}
                name="preferred_payment_type"
                options={manageView.paymentOptions}
                error={errors?.preferred_payment_type?.message}
                isMandatory={true}
              />
            </div>

            {/* Advance Payment */}
            <div className="col-12">
              <Controller
                control={control}
                name="allowance_advance_payment"
                render={({ field: { onChange, value } }) => (
                  <SwitchInput
                    label="Advanced Payment"
                    isMandatory={false}
                    value={value}
                    callback={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <ImgPreviewModal
        show={showImgPreviewModal}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewDataRef.current}
      />
    </>
  );
};

export default KycDetails;
