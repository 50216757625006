import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { viewVendor } from "../../../../constantService";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Completed", value: "Completed" },
  { label: "Partial Paid", value: "Partially Paid" },
  { label: "Pending", value: "Pending" },
];

const defaultFilterFormData = viewVendor.tabData.intakeSummary.filterFormData;

const DateConfig = {
  mode: "range",
};

const Filter = ({ callback }) => {
  const { register, control, reset, getValues } = useForm({
    defaultValues: defaultFilterFormData,
  });

  const onReset = () => {
    reset(defaultFilterFormData);
    callback({ formData: defaultFilterFormData });
  };

  const onSearch = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 800),
    []
  );

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    callback({ formData: getValues() });
  };

  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-2">
          {/* GRN ID */}
          <TextInput
            name="grnId"
            register={register}
            label="GRN ID"
            placeholder="Search By GRN ID"
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-2">
          {/* UTR Number */}
          <TextInput
            name="toBankUtr"
            register={register}
            label="UTR Number"
            type="text"
            placeholder="Enter ID here.."
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-3">
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Select Date"
                value={value}
                callback={onDateChange(onChange)}
                label="Date Range"
                config={DateConfig}
                hideClose={true}
                template={2}
                gap={1}
              />
            )}
          />
        </div>
        <div className="col-2">
          {/* Payment Status */}
          <SelectInput
            name="paymentStatus"
            register={register}
            label="Status"
            options={statusOptions}
            callback={onSearch}
            gap={1}
          />
        </div>
        {/* Action Button */}
        <div className="col-2 align-self-end">
          <button
            className="btn btn-outline-dark fs-val-md mb-1"
            type="button"
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  );
};

export default memo(Filter);
