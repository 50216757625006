import { VendorService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { viewVendor } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const prepareFilterParams = (filter = {}, pagination = {}) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  if (filter?.search?.length) {
    p.filter.$or = [
      {
        _id: filter?.search,
      },
      {
        name: {
          $regex: filter?.search,
          $options: "i",
        },
      },
    ];
  }

  if (filter?.status) {
    p.filter.status = filter?.status;
  }

  if (!Object.keys(filter).length) {
    delete p.filter;
  }

  return p;
};

const defaultPaginationOpt = viewVendor.tabData.products.pagination;

const defaultFilterFormData = viewVendor.tabData.products.filterFormData;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const getData = async (id, params) => {
  const r = await VendorService.getVendorProductsList(id, params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (id, params) => {
  const r = await VendorService.getVendorProductsCount(id, params);
  return r.resp ?? 0;
};

const Products = ({ vendorId }) => {
  const filterDataRef = useRef({ ...defaultFilterFormData });

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
  }, []);

  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);

    const p = getFilterParams();

    const count = await getCount(vendorId, p);

    paginationRef.current.totalRecords = count;

    setLoadingTotalRecords(false);
  }, [getFilterParams, vendorId]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();

    const d = await getData(vendorId, p);

    setData(d);

    setLoadingData(false);
  }, [getFilterParams, vendorId]);

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPaginationOpt };

    loadList();

    loadCount();
  }, [loadList, loadCount]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = {
        ...filterDataRef.current,
        ...data.formData,
      };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <div>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>
      <AppCard>
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </div>
  );
};

export default Products;
