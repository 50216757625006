import {
  Amount,
  DateFormatter,
  HighlightText,
  KeyVal,
  PaginationSummary,
  TableHeader,
  NoDataFound,
  BtnLink,
} from "@sk/uis";
import { memo, useCallback, useRef, useState } from "react";
import ViewAutoDebitAdjustmentModal from "../modals/ViewAutoDebitAdjustmentModal";
import ViewGrnProductGstModal from "../modals/ViewGrnProductGstModal";
import ViewPoProductGstModal from "../modals/ViewPoProductGstModal";

const headers = [
  { label: "Sl No" },
  { label: "PO Details", width: "20%" },
  { label: "GRN Details", width: "20%" },
  { label: "Invoice Date", width: "24%" },
  { label: "Auto / Manual Debit Adjustment", width: "10%" },
  { label: "Payment Status" },
  { label: "Payment Details" },
];

const poHeaders = [
  { label: "#" },
  { label: "PO ID" },
  { label: "Date" },
  { label: "Value" },
  { label: "Status" },
];

const invoiceHeaders = [
  { label: "#" },
  { label: "Invoice No" },
  { label: "Date" },
  { label: "Value" },
];

const Table = ({ data, paginationConfig, loadingTotalRecords, callback }) => {
  const [showPoProductGstModal, setShowPoProductGstModal] = useState(false);
  const [showGrnProductGstModal, setShowGrnProductGstModal] = useState(false);
  const [showAutoDebitAdjustmentModal, setShowAutoDebitAdjustmentModal] =
    useState(false);

  const poProductGstModalRef = useRef({ id: "" });
  const grnProductGstModalRef = useRef({ id: "" });
  const autoDebitAdjustmentModalRef = useRef({ id: "" });

  const openPoProductGstModal = useCallback((id) => {
    poProductGstModalRef.current.id = id;
    setShowPoProductGstModal(true);
  }, []);

  const closePoProductGstModal = useCallback(() => {
    poProductGstModalRef.current.id = "";
    setShowPoProductGstModal(false);
  }, []);

  const openGrnProductGstModal = useCallback((id) => {
    grnProductGstModalRef.current.id = id;
    setShowGrnProductGstModal(true);
  }, []);

  const closeGrnProductGstModal = useCallback(() => {
    grnProductGstModalRef.current.id = "";
    setShowGrnProductGstModal(false);
  }, []);

  const openAutoDebitAdjustmentModal = useCallback((id) => {
    autoDebitAdjustmentModalRef.current.id = id;
    setShowAutoDebitAdjustmentModal(true);
  }, []);

  const closeAutoDebitAdjustmentModal = useCallback(() => {
    autoDebitAdjustmentModalRef.current.id = "";
    setShowAutoDebitAdjustmentModal(false);
  }, []);

  const loadMore = () => {
    callback({ action: "loadMore" });
  };

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-3"
      />

      {!loadingTotalRecords && data.length === 0 ? (
        <NoDataFound>No data found</NoDataFound>
      ) : (
        data.map((x, index) => (
          <div
            key={x._id + ":" + index}
            className="bg-white mb-2 p-2 rounded shadow-sm"
          >
            <div className="row">
              <div className="col-4">
                <div className="fs-val-md mb-2 fw-semibold">
                  <i className="bi bi-file-earmark-text"></i> PO Details
                </div>
                <table className="table table-sm table-bordered">
                  <TableHeader data={poHeaders} />
                  <tbody className="fs-val-sm">
                    {x.poDetails.map((poDetail, poIndex) => (
                      <tr key={poIndex}>
                        <td>{poIndex + 1}</td>
                        <td>
                          <BtnLink
                            onClick={() => openPoProductGstModal(poDetail.poId)}
                            className="fs-val-sm"
                          >
                            {poDetail.poId ?? "N/A"}
                          </BtnLink>
                        </td>
                        <td>
                          <DateFormatter
                            date={poDetail.poDate}
                            format="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Amount value={poDetail.poValue} decimalPlace={2} />
                        </td>
                        <td>
                          <HighlightText
                            status={poDetail.poStatus}
                            isBadge={true}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-2">
                <div className="fs-val-md mb-2 fw-semibold">
                  <i className="bi bi-file-earmark-text"></i> GRN Details
                </div>
                <div className="border p-2 rounded">
                  <KeyVal label="ID">
                    :{" "}
                    <BtnLink
                      onClick={() => openGrnProductGstModal(x._id)}
                      className="fs-val-sm"
                    >
                      {x._id ?? "N/A"}
                    </BtnLink>
                  </KeyVal>
                  <KeyVal label="Date">
                    : <DateFormatter date={x.createdAt} format="dd MMM yyyy" />
                  </KeyVal>
                  <KeyVal label="Value">
                    : <Amount value={x.amount} decimalPlace={2} />
                  </KeyVal>
                  <KeyVal label="Status">
                    : <HighlightText status={x.status} isBadge={true} />
                  </KeyVal>
                </div>
              </div>

              <div className="col-3">
                <div className="fs-val-md mb-2 fw-semibold">
                  <i className="bi bi-file-earmark-text"></i> Invoice Details
                </div>
                <table className="table table-sm table-bordered">
                  <TableHeader data={invoiceHeaders} />
                  <tbody className="fs-val-sm">
                    {x.invoiceDetails?.map((invoiceDetail, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{invoiceDetail.no ?? "N/A"}</td>
                        <td>
                          <DateFormatter
                            date={invoiceDetail.date}
                            format="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Amount
                            value={invoiceDetail.amount}
                            decimalPlace={2}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-3">
                <div className="fs-val-md mb-2 fw-semibold">
                  <i className="bi bi-file-earmark-text"></i> Payment Details
                </div>
                <div className="border p-2 rounded">
                  <KeyVal label="Remaining" labelCol="col-6" contentCol="col-6">
                    :{" "}
                    <span className="text-danger">
                      <Amount value={x.payableAmount} decimalPlace={2} />
                    </span>
                  </KeyVal>
                  <KeyVal
                    label="Total Paid"
                    labelCol="col-6"
                    contentCol="col-6"
                  >
                    : <Amount value={x.amount} decimalPlace={2} />
                  </KeyVal>
                  <KeyVal
                    label="Payment Status"
                    labelCol="col-6"
                    contentCol="col-6"
                  >
                    : <HighlightText status={x.paymentStatus} isBadge={true} />
                  </KeyVal>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {data.length < paginationConfig.totalRecords && data.length > 0 && (
        <div className="text-center">
          <button className="btn btn-sm btn-light" onClick={loadMore}>
            Load More ({data.length} / {paginationConfig.totalRecords})
          </button>
        </div>
      )}

      <ViewPoProductGstModal
        show={showPoProductGstModal}
        closeModalCb={closePoProductGstModal}
        id={poProductGstModalRef.current.id}
      />

      <ViewGrnProductGstModal
        show={showGrnProductGstModal}
        closeModalCb={closeGrnProductGstModal}
        id={grnProductGstModalRef.current.id}
      />

      <ViewAutoDebitAdjustmentModal
        show={showAutoDebitAdjustmentModal}
        closeModalCb={closeAutoDebitAdjustmentModal}
        id={autoDebitAdjustmentModalRef.current.id}
      />
    </>
  );
};

export default memo(Table);
