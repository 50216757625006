import { AppTitle } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";
import BrandMargin from "../../view/tabs/brand-margin/BrandMargin.jsx";
// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "75%",
  },
};

const MarginListModal = ({ show, closeCb, data }) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <Offcanvas.Title>
            <AppTitle
              title="Vendor Margin List"
              className="fs-val-lg text-dark px-2"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <>
            <div className="p-4 bg-light">
              <div className="row">
                <div className="col-12">
                  <div className="fw-semibold fs-val-lg mb-3">
                    Vendor Details
                  </div>
                  {/* Franchise Details */}
                  <div className="col-12">
                    <div className="fw-semibold fs-val-lg text-primary">
                      {data?.name}-({data?._id})
                    </div>
                    <span className=" fw-semibold fs-val-sm">
                      {data?.city}, {data?.district}, {data?.state}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {data?.margins && show ? (
              <div className="p-4">
                <BrandMargin
                  margins={data?.margins}
                  vid={data?._id}
                  showAddMarginOpt={false}
                />
              </div>
            ) : null}
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MarginListModal;
