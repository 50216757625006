import {
  Amount,
  AppCard,
  HighlightText,
  ImgPreviewModal,
  KeyVal,
} from "@sk/uis";
import { useCallback, useRef, useState } from "react";
import ContactDetails from "../contact-details/ContactDetails";
import ViewBankModal from "./modals/ViewBankModal";
import VendorViewKycBank from "./VendorViewKycBank";
import VendorViewKycContract from "./VendorViewKycContract";
import { CommonService } from "@sk/services";

const KycDetails = ({ data }) => {
  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [showViewBankModal, setShowViewBankModal] = useState(false);

  const viewBankModalRef = useRef({ data: [] });

  const imgPreviewDataRef = useRef([]);

  const viewImgPreviewModal = (id) => {
    const fileExtension = id.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

    if (!imageExtensions.includes(fileExtension)) {
      CommonService.downloadAsset(id, true);
      return;
    }

    let d = [{ id }];
    imgPreviewDataRef.current = d;
    setShowImgPreviewModal(true);
  };

  const closeImgPreviewModal = () => {
    imgPreviewDataRef.current = [];
    setShowImgPreviewModal(false);
  };

  const openViewBankModal = useCallback((x) => {
    viewBankModalRef.current.data = x;
    setShowViewBankModal(true);
  }, []);

  const closeViewBankModal = useCallback(() => {
    viewBankModalRef.current.data = [];
    setShowViewBankModal(false);
  }, []);

  return (
    <>
      <div className="row">
        {/* Aadhar and Pan Card Details */}
        <div className="col-6">
          <AppCard noShadow={true} title="Aadhar /PAN /GST">
            <div className="row mb-2">
              <div className="col-4">
                <KeyVal label="Aadhar No" template="col">
                  {data.aadharNo || "--"}
                </KeyVal>
              </div>

              <div className="col-4">
                <KeyVal label="PAN No" template="col">
                  {data.pan || "--"}
                </KeyVal>
              </div>

              <div className="col-4">
                <KeyVal label="GST No" template="col">
                  {data.gst_no || "--"}
                </KeyVal>
              </div>
            </div>
            <div className="row">
              {/* Aadhar doc */}
              <div className="col-4">
                <KeyVal label="Aadhar Document" template="col">
                  {data.aadharDocs.length > 0
                    ? data.aadharDocs.map((x, i) => (
                        <div
                          role="button"
                          tabIndex={-1}
                          key={"aadhar" + i}
                          className="text-primary text-truncate"
                          onClick={() => viewImgPreviewModal(x.value)}
                        >
                          {x.filename}
                        </div>
                      ))
                    : "--"}
                </KeyVal>
              </div>

              {/* Pan Doc */}
              <div className="col-4">
                <KeyVal label="PAN Document" template="col">
                  {data.panDocs.length > 0
                    ? data.panDocs.map((x, i) => (
                        <div
                          role="button"
                          tabIndex={-1}
                          key={"pan" + i}
                          className="text-primary text-truncate"
                          onClick={() => viewImgPreviewModal(x.value)}
                        >
                          {x.filename}
                        </div>
                      ))
                    : "--"}
                </KeyVal>
              </div>

              {/* GST Document */}
              <div className="col-4">
                <KeyVal label="GST Document" template="col">
                  {data.gstDocs.length > 0
                    ? data.gstDocs.map((x, i) => (
                        <div
                          role="button"
                          tabIndex={-1}
                          key={"gst" + i}
                          className="text-primary text-truncate"
                          onClick={() => viewImgPreviewModal(x.value)}
                        >
                          {x.filename}
                        </div>
                      ))
                    : "--"}
                </KeyVal>
              </div>
            </div>
          </AppCard>
        </div>
        {/* Credit And Payment Details */}
        <div className="col-6 align-self-stretch">
          <AppCard noShadow={true} title="Credit / Payment Details">
            <div className="row mb-2">
              <div className="col-4">
                <KeyVal label="Credit Limit" template="col">
                  <Amount value={data.credit_limit} />
                </KeyVal>
              </div>

              <div className="col-4">
                <KeyVal label="Credit Day" template="col">
                  {data.credit_days}{" "}
                  {data.credit_days == 1 ? "Day" : "Days" ?? "--"}
                </KeyVal>
              </div>

              <div className="col-4">
                <KeyVal label="Advance Payment" template="col">
                  <HighlightText
                    status={data.allowance_advance_payment ? "Yes" : "No"}
                  />
                </KeyVal>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <KeyVal label="Preferred Payments" template="col">
                  {data.preferred_payment_type}
                </KeyVal>
              </div>

              <div className="col-4">
                <KeyVal label="Average Delivery Tat" template="col">
                  {data.average_tat} {data.average_tat == 1 ? "Day" : "Days"}
                </KeyVal>
              </div>
            </div>
          </AppCard>
        </div>

        <div className="col-6 mb-3">
          <AppCard
            noShadow={true}
            title="Registered Office Address"
            className="h-100"
          >
            <div>
              <div className="fs-val-md">
                {data?.address?.line1} {data?.address?.line2}
              </div>
              <div className="fs-val-md text-muted">
                {data?.state}, {data?.district || "--"}, {data?.city} -{" "}
                {data?.pincode}
              </div>
            </div>
          </AppCard>
        </div>
        <div className="col-6 mb-3">
          <AppCard
            noShadow={true}
            title="Communication Address"
            className="h-100"
          >
            <div>
              <div className="fs-val-md">
                {data.communication?.line1} {data.communication?.line2}
              </div>
              <div className="fs-val-md text-muted">
                {data?.communication?.state}, {data?.communication?.district},{" "}
                {data?.communication?.city} - {data?.communication?.pincode}
              </div>
            </div>
          </AppCard>
        </div>

        {/* Bank Details */}
        <div className="col-12">
          <VendorViewKycBank bankData={data.bankDetails} />
        </div>
        {/* Contract Details */}
        <div className="col-12">
          <VendorViewKycContract contractData={data.contract} />
        </div>
        {/* Contact Details */}
        <div className="col-12">
          <ContactDetails data={data.contact} />
        </div>
      </div>

      <ImgPreviewModal
        show={showImgPreviewModal}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewDataRef.current}
      />

      <ViewBankModal
        show={showViewBankModal}
        closeModalCb={closeViewBankModal}
        data={viewBankModalRef.current.data}
      />
    </>
  );
};

export default KycDetails;
