import { HighlightText, TableHeader } from "@sk/uis";
import { Offcanvas } from "react-bootstrap";

const headers = [
  { label: "Sl.no" },
  { label: "Bank Name" },
  { label: "Account Number" },
  { label: "Account Holder" },
  { label: "IFSC Code" },
  { label: "Status" },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "65%",
  },
};

const ViewBankModal = ({ show = false, closeModalCb, data }) => {
  return (
    <Offcanvas
      show={show}
      onHide={closeModalCb}
      placement="end"
      style={style.offCanvasStyle}
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={style.offCanvasHeaderStyle}
      >
        <Offcanvas.Title>
          <div className="page-title"> Banks Details </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 py-5  border-top">
        {show ? (
          <>
            <>
              <table className="table table-bordered table-sm">
                <TableHeader data={headers} />
                <tbody>
                  {/* No Data Found */}
                  {data.length == 0 && (
                    <tr>
                      <td
                        colSpan={headers.length}
                        className="fs-val-sm text-center"
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                  {/* No  DAta */}
                  {data.length > 0 &&
                    data.map((item, i) => (
                      <tr key={i} className="fs-val-sm">
                        <td className="text-center">{i + 1}</td>
                        <td>
                          {item.bankName} - {item.bankId}
                        </td>
                        <td>{item.accountNumber}</td>
                        <td>{item.accountHolderName}</td>
                        <td>{item.ifsc}</td>
                        <td>
                          <HighlightText
                            status={item.isActive ? "Yes" : "No"}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewBankModal;
