import {
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl No", width: "50px" },
  { label: "ID", width: "100px" },
  { label: "Name", width: "250px" },
  { label: "GST No", width: "150px" },
  { label: "Email", width: "200px" },
  { label: "Mobile Number", width: "150px" },
  { label: "State", width: "100px" },
  { label: "District", width: "100px" },
  { label: "Town", width: "100px" },
  { label: "Type", width: "100px" },
  { label: "Warehouse", width: "150px" },
  { label: "Vendor Category", width: "150px", isCentered: true },
  { label: "Status", width: "100px", isCentered: true },
  { label: "Actions", width: "150px", isCentered: true },
];

const rbac = {
  viewButton: ["ViewVendor"],
  editButton: ["EditVendor"],
};

const tableStyle = {
  width: "2000px",
};

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
  overflowX: "auto", // Added for horizontal scroll
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) => {
  return (
    <>
      <div style={tableContainerStyle} className="mb-3">
        <table className="table table-hover table-sm" style={tableStyle}>
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Vendors Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {!loading && (
              <>
                {data.map((x, index) => (
                  <tr
                    key={x._id}
                    className="fs-val-md cursor-pointer"
                    onClick={() => callback({ action: "view", id: x._id })}
                  >
                    <td>{paginationConfig.startSlNo + index}</td>
                    <td>{x?._id}</td>
                    <td>{x?.name}</td>
                    <td>{x?.gst_no}</td>
                    <td>{x?.contact?.[0]?.email}</td>
                    <td>{x?.contact?.[0]?.mobile}</td>
                    <td>{x?.state}</td>
                    <td>{x?.district}</td>
                    <td>{x?.city}</td>
                    <td>{x?.vendorType}</td>
                    <td>{x?.whIds.join(", ")}</td>
                    <td className="text-center">{x?.vendorCategory}</td>
                    <td className="text-center">
                      <HighlightText
                        type={x.status === "Active" ? "success" : "danger"}
                        template={2}
                      >
                        {x.status}
                      </HighlightText>
                    </td>
                    <td className="text-center">
                      <Rbac roles={rbac.viewButton}>
                        <button
                          className="btn btn-sm btn-outline-primary me-2 fs-val-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            callback({ action: "view", id: x._id });
                          }}
                        >
                          View
                        </button>
                      </Rbac>
                      {/* <Rbac roles={rbac.editButton}>
                        <button
                          className="btn btn-sm btn-outline-warning me-2 fs-val-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            callback({ action: "edit", id: x._id });
                          }}
                        >
                          Edit
                        </button>
                      </Rbac> */}
                      <button
                        className="btn btn-sm btn-outline-primary fs-val-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          callback({ action: "viewMargin", data: x });
                        }}
                      >
                        View Margin
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};
export default Table;
