import { CommonService } from "@sk/services";
import { AppCard, BtnLink, HighlightText, KeyVal } from "@sk/uis";

const VendorViewBasic = ({ data }) => {
  const handleViewOnMap = () => {
    const [lat, lng] = data.geolocation.coordinates;
    CommonService.openGoogleMap(lat, lng);
  };

  return (
    <AppCard>
      <div className="row align-items-center g-2">
        <div className="col-6">
          <div className="fs-val-xl fw-bold mb-2">{data.name}</div>
          <div className="d-flex gap-2">
            <HighlightText type="primary" template={2} isSoft>
              {data._id}
            </HighlightText>
            <HighlightText
              type={data.status === "Active" ? "success" : "danger"}
              template={2}
              isSoft
            >
              <span className="fw-semibold text-uppercase">{data.status}</span>
            </HighlightText>
            <span>
              <i className="bi bi-envelope-fill me-1"></i>
              {data._contactDetails?.email || "--"}
            </span>
          </div>
          <div className="mt-2 fs-val-sm">
            <div className="fs-val mb-1">
              {data?.address?.line1} {data?.address?.line2}
            </div>
            <div className="text-muted d-flex gap-2 justify-between">
              <div className="flex-grow-1">
                {data?.address?.city}, {data?.address?.district || "--"},{" "}
                {data?.address?.state}, - {data?.address?.pincode}
              </div>
              <div>
                <BtnLink onClick={handleViewOnMap} className="fs-val-sm mx-2">
                  <i className="bi bi-geo-alt-fill me-1"></i>
                  View on Map
                </BtnLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          {/* GST Number */}
          <KeyVal
            label="GST No"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data.gst_no || "--"}
          </KeyVal>
          <KeyVal
            label="Category"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data.vendorCategory}
          </KeyVal>

          <KeyVal
            label="Vendor Type"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data.vendorType}
          </KeyVal>
        </div>
        <div className="col-3">
          {/* Mobile Number */}
          <KeyVal
            label="Mobile No"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data?._contactDetails?.mobile || "--"}
          </KeyVal>
          <KeyVal
            label="Entity"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data.entity ?? "--"}
          </KeyVal>
          <KeyVal
            label="Avg Delivery TAT"
            labelCol="col-5"
            contentCol="col-7"
            className="fw-semibold"
          >
            : {data.average_tat || "0"} {data.average_tat == 1 ? "Day" : "Days"}
          </KeyVal>
        </div>
      </div>
    </AppCard>
  );
};

export default VendorViewBasic;
