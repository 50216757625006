import { NavService } from "@sk/services";
import {
  Amount,
  BtnLink,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Product Name", width: "20%" },
  { label: "Product ID", width: "10%" },
  { label: "MRP", width: "10%" },
  { label: "Dealer Price", width: "10%" },
  { label: "Case Qty", width: "10%" },
  { label: "Inner Case Qty", width: "10%" },
  { label: "Barcode", width: "15%" },
  { label: "Status", width: "10%" },
];

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", {
      id: id,
    });
  };

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-2"
      />
      <div style={tableContainerStyle} className="mb-3">
        <table className="table table-sm">
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            noBg={true}
            isSticky={true}
          />

          {/* Table Body */}
          <tbody>
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No loading */}
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Grn Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {/* When Not Loading and Data is there  */}
            {!loading &&
              data.map((x, index) => (
                <tr key={x._id} className="fs-val-md">
                  {/* Serial Number */}
                  <td>{paginationConfig.startSlNo + index}</td>

                  {/* Product Name  */}
                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={() => viewProduct(x._id)}
                    >
                      {x.name}
                    </BtnLink>
                  </td>

                  {/* Product Id */}
                  <td>{x._id}</td>

                  {/* MRP */}
                  <td>
                    <Amount value={x.mrp} />
                  </td>

                  {/* Dealer Price */}
                  <td>
                    <Amount value={x.dealer_price} />
                  </td>

                  {/* Case Qty */}
                  <td>{x.caseQty}</td>

                  {/* Inner Case Qty */}
                  <td>{x.innerPackQty}</td>

                  {/* Barcode  */}
                  <td>{x.barcode}</td>

                  {/* Status */}
                  <td>
                    <HighlightText
                      type={x.status == "Active" ? "success" : "danger"}
                      template={2}
                    >
                      {x.status}
                    </HighlightText>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
