import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { viewVendor } from "../../../../constantService";

const DateConfig = {
  mode: "range",
};

const statusOptions = [
  { label: "All", value: "" },
  { label: "Draft", value: "Draft" },
  { label: "Submitted", value: "Submitted" },
  { label: "Open", value: "Open" },
  { label: "Partially Inwarded", value: "Partially Inwarded" },
  { label: "Closed", value: "Closed" },
  { label: "Rejected", value: "Rejected" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Deleted", value: "Deleted" },
];

const defaultFilterFormData = viewVendor.tabData.purchaseOrder.filterFormData;

const Filter = ({ callback }) => {
  const { register, control, reset, getValues } = useForm({
    defaultValues: defaultFilterFormData,
  });

  const onReset = () => {
    reset(defaultFilterFormData);
    callback({ formData: defaultFilterFormData });
  };

  const onSearch = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 800),
    []
  );

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    callback({ formData: getValues() });
  };

  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-3">
          <TextInput
            name="poId"
            register={register}
            label="PO ID"
            placeholder="Search By PO ID"
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-3">
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Select Date"
                value={value}
                callback={onDateChange(onChange)}
                label="Date Range"
                config={DateConfig}
                hideClose={true}
                template={2}
                gap={1}
              />
            )}
          />
        </div>
        <div className="col-2">
          <SelectInput
            name="status"
            register={register}
            label="Status"
            options={statusOptions}
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-2 align-self-end">
          <button
            className="btn btn-outline-dark fs-val-md mb-1"
            type="button"
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  );
};

export default memo(Filter);
