import { AppCard, DateFormatter, NoDataFound, KeyVal } from "@sk/uis";

const VendorViewKycContract = ({ contractData }) => {
  return (
    <AppCard noShadow={true} title={`Contract Details`}>
      <div className="row">
        {!contractData ? (
          <NoDataFound>No contract details available.</NoDataFound>
        ) : (
          <>
            <div className="col-3">
              <KeyVal label="Serial Number" labelCol="col-4" contentCol="col-8">
                {contractData.serialNo ?? "N/A"}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal
                label="Date Of Contract"
                labelCol="col-4"
                contentCol="col-8"
              >
                {contractData.dateOfContract ? (
                  <DateFormatter
                    date={contractData.dateOfContract}
                    format="dd MMM yyyy"
                  />
                ) : (
                  "N/A"
                )}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal
                label="Period Of Validity"
                labelCol="col-4"
                contentCol="col-8"
              >
                {contractData.periodOfValidity ? (
                  <DateFormatter
                    date={contractData.periodOfValidity}
                    format="dd MMM yyyy"
                  />
                ) : (
                  "N/A"
                )}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal label="Renewal Date" labelCol="col-4" contentCol="col-8">
                {contractData.renewalDate ? (
                  <DateFormatter
                    date={contractData.renewalDate}
                    format="dd MMM yyyy"
                  />
                ) : (
                  "N/A"
                )}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal
                label="Service Period"
                labelCol="col-4"
                contentCol="col-8"
              >
                {contractData.servicePeriod ? (
                  <DateFormatter
                    date={contractData.servicePeriod}
                    format="dd MMM yyyy"
                  />
                ) : (
                  "N/A"
                )}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal
                label="E-Stamp Amount"
                labelCol="col-4"
                contentCol="col-8"
              >
                {contractData.eStampAmount ?? "N/A"}
              </KeyVal>
            </div>
            <div className="col-3">
              <KeyVal
                label="Is Auto Renewal"
                labelCol="col-4"
                contentCol="col-8"
              >
                {contractData.contractIsAutoRenewal ? "Yes" : "No"}
              </KeyVal>
            </div>
          </>
        )}
      </div>
    </AppCard>
  );
};

export default VendorViewKycContract;
