import {
  DatePickerInput,
  FileUpload,
  ImgRender,
  InputErrorMsg,
  SwitchInput,
  TextInput,
} from "@sk/uis";
import { useCallback, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const ContractDetails = () => {
  const {
    register,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const imgPreviewDataRef = useRef([]);

  const onDateChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val.length ? val[0] : "");
    },
    []
  );

  const msmeDocCb = async (e) => {
    const d = getValues("msmeDoc") || [];
    d.push(getImageDocObject("GST Document", e));
    setValue("msmeDoc", d);
    await trigger("msmeDoc");
  };

  const getImageDocObject = (name, e) => {
    return {
      inputGroupCode: "Address Proof Details",
      name: name,
      type: e.resp.type,
      filename: e.resp.originalName,
      value: e.asset,
      assetId: e.asset,
    };
  };

  const viewImgPreviewModal = (id) => {
    let d = [{ id: id }];
    imgPreviewDataRef.current = d;
    setShowImgPreviewModal(true);
  };

  const cancelChequeDocCb = async (e) => {
    const d = getValues("cancelChequeDoc") || [];
    d.push(getImageDocObject("Cancel Cheque", e));
    setValue("cancelChequeDoc", d);
    await trigger("cancelChequeDoc");
  };

  const removeUploadedDoc = async (key, index) => {
    let d = getValues(key);
    d.splice(index, 1);
    setValue(key, d);
    await trigger(key);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
            Contract Details
          </div>
        </div>
        <div className="row">
          {/*Serial Number  */}
          <div className="col-2">
            <TextInput
              type="text"
              label="Serial Number"
              placeholder="Serial Number"
              register={register}
              name="contractSerialNo"
              error={errors?.contractSerialNo?.message}
              isMandatory={true}
            />
          </div>

          {/* Date of Contract   */}
          <div className="col-2">
            <label className="mb-1 fs-val-md">
              Date Of Contract <span className="text-danger">*</span>
            </label>
            <Controller
              name="contractDateOfContract"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Select Date"
                  value={[value]}
                  inpChange={onDateChange(onChange)}
                  isMandatory={true}
                />
              )}
            />

            <InputErrorMsg msg={errors?.contractDateOfContract?.message} />
          </div>

          {/* Period of Validity   */}
          <div className="col-2">
            <label className="mb-1 fs-val-md">
              Period Of Validity
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="contractPeriodOfValidity"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Select Date"
                  value={[value]}
                  inpChange={onDateChange(onChange)}
                  isMandatory={true}
                />
              )}
            />

            <InputErrorMsg msg={errors?.contractPeriodOfValidity?.message} />
          </div>

          {/* Renewal date as per Contract   */}
          <div className="col-2">
            <label className="mb-1 fs-val-md">
              Renewal date as per Contract
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="contractRenewalDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Select Date"
                  value={[value]}
                  inpChange={onDateChange(onChange)}
                  isMandatory={true}
                />
              )}
            />

            <InputErrorMsg msg={errors?.contractRenewalDate?.message} />
          </div>

          {/* Service Period   */}
          <div className="col-2">
            <label className="mb-1 fs-val-md">
              Service Period
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="contractServicePeriod"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Select Date"
                  value={[value]}
                  inpChange={onDateChange(onChange)}
                  isMandatory={true}
                />
              )}
            />

            <InputErrorMsg msg={errors?.contractServicePeriod?.message} />
          </div>

          {/* e-Stamping Amount  */}

          <div className="col-2">
            <TextInput
              type="number"
              label="e-Stamping Amount"
              placeholder="e-Stamping Amount"
              register={register}
              name="contractEStampAmount"
              error={errors?.contractEStampAmount?.message}
              isMandatory={true}
            />
          </div>

          {/* e-Stamping Date  */}
          <div className="col-2">
            <label className="mb-1 fs-val-md">
              e-Stamping Date
              <span className="text-danger">*</span>
            </label>
            <Controller
              name="contractEStampDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Select Date"
                  value={[value]}
                  inpChange={onDateChange(onChange)}
                />
              )}
            />

            <InputErrorMsg msg={errors?.contractEStampDate?.message} />
          </div>

          {/* Is Auto Renewal */}
          <div className="col-2 mb-2">
            <Controller
              control={control}
              name="contractIsAutoRenewal"
              render={({ field: { onChange, value } }) => (
                <SwitchInput
                  label="Is Auto Renewal"
                  isMandatory={false}
                  value={value}
                  callback={onChange}
                />
              )}
            />
          </div>

          {/* MSME Doc */}
          <div className="col-4">
            {getValues("msmeDoc")?.length < 2 ? (
              <>
                <FileUpload template={3} callback={msmeDocCb} />
                <label className="fs-val-md">
                  MSME Registration Certificate
                </label>
              </>
            ) : null}

            <div className="mt-3">
              {getValues("msmeDoc")?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    Uploaded Cancelled Cheque Document
                    <span className="text-danger">*</span>
                  </div>
                  {getValues("msmeDoc").map((x, i) => (
                    <div className="col-6 text-center" key={i}>
                      <div
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                          viewImgPreviewModal(x?.value);
                        }}
                      >
                        <ImgRender
                          template="2"
                          assetId={x.assetId}
                          width="200"
                          height="200"
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-danger fs-val-sm  mt-2 "
                        onClick={() => {
                          removeUploadedDoc("msmeDoc", i);
                        }}
                      >
                        Remove
                        <i className="bi bi-trash-fill text-danger fs-6 pt-2 align-middle ms-2"></i>
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          {/* Cancelled Cheque Document */}
          <div className="col-4">
            {getValues("cancelChequeDoc")?.length < 2 ? (
              <>
                <FileUpload template={3} callback={cancelChequeDocCb} />
                <label className="fs-val-md">
                  Cancelled Cheque Document
                  <span className="text-danger">*</span>
                </label>
                <InputErrorMsg msg={errors?.cancelChequeDoc?.message} />
              </>
            ) : null}

            <div className="mt-3">
              {getValues("cancelChequeDoc")?.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    Uploaded Cancelled Cheque Document
                    <span className="text-danger">*</span>
                  </div>
                  {getValues("cancelChequeDoc").map((x, i) => (
                    <div className="col-6 text-center" key={i}>
                      <div
                        role="button"
                        tabIndex="-1"
                        onClick={() => {
                          viewImgPreviewModal(x?.value);
                        }}
                      >
                        {" "}
                        <ImgRender
                          template="2"
                          assetId={x.value}
                          width="200"
                          height="200"
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-danger fs-val-sm  mt-2 "
                        onClick={() => {
                          removeUploadedDoc("cancelChequeDoc", i);
                        }}
                      >
                        Remove
                        <i className="bi bi-trash-fill text-danger fs-6 pt-2 align-middle ms-2"></i>
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractDetails;
