import { AuthService } from "@sk/services";
import { SelectInput, SwitchInput, TextInput } from "@sk/uis";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import listView from "../../constantService";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((e) => {
  return { label: e.name, value: e._id };
});
warehouseOptions.unshift({ label: "Select Warehouse", value: "" });

const BasicDetails = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const isWarehouseVendor = useWatch({
    control,
    name: "isWarehouseVendor",
  });

  return (
    <>
      <div className="row mt-3">
        {/* Vendor Name */}
        <div className="col-3">
          <TextInput
            type="text"
            label="Vendor Name"
            placeholder="Enter Vendor Name Here"
            register={register}
            name="name"
            error={errors?.name?.message}
            isMandatory={true}
          />
        </div>

        {/* Nature of Entity  */}
        <div className="col-3">
          <TextInput
            type="text"
            label="Nature Of Entity"
            placeholder="Enter nature of entity"
            register={register}
            name="entity"
            error={errors?.entity?.message}
            isMandatory={true}
            info={{
              content: (
                <ol>
                  <li>Company Pvt Ltd</li>
                  <li>Public Company</li>
                  <li>Partnership</li>
                  <li>Proprietorship</li>
                </ol>
              ),
            }}
          />
        </div>

        {/* Vendor Type */}
        <div className="col-2">
          <SelectInput
            label="Vendor Type"
            register={register}
            name="vendorType"
            error={errors?.vendorType?.message}
            isMandatory={true}
            options={listView.formVendorTypeOptions}
          />
        </div>

        {/* Vendor Category */}
        <div className="col-2">
          <SelectInput
            label="Vendor Category"
            register={register}
            name="vendorCategory"
            error={errors?.vendorCategory?.message}
            isMandatory={true}
            options={listView.formCategoryOptions}
          />
        </div>

        {/* Status */}
        <div className="col-auto">
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Status"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>

        {/* Enable Purchase to POS */}
        <div className="col-3 mt-3">
          <Controller
            control={control}
            name="enablePurchaseToPOS"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Enable Purchase to POS"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>

        {/* Is WarehouseVendor */}
        <div className="col-3 mt-3">
          <Controller
            control={control}
            name="isWarehouseVendor"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Is Warehouse Vendor"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>

        {/* Warehouse */}
        {isWarehouseVendor && (
          <div className="col-3 mt-3">
            <SelectInput
              type="text"
              label="Choose Warehouse"
              register={register}
              name="warehouseDataId"
              error={errors?.warehouseDataId?.message}
              options={warehouseOptions}
              isMandatory={true}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BasicDetails;
