import React from "react";
import {
  AppCard,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Bank Name", width: "20%" },
  { label: "Account Number", width: "20%" },
  { label: "Account Holder Name", width: "20%" },
  { label: "Branch Name", width: "20%" },
  { label: "IFSC Code", width: "10%" },
  { label: "Is Active", width: "10%", isCentered: true },
];

const tableStyle = {
  width: "100%",
};

const VendorViewKycBank = ({ bankData, loading }) => {
  return (
    <AppCard noShadow={true} title={`Bank Details (${bankData.length})`}>
      {/* <Rbac /> */}

      <div>
        <table className="table table-hover table-sm" style={tableStyle}>
          <TableHeader data={headers} isSticky={true} noBg={true} />
          <tbody>
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No loading */}
            {!loading && !bankData.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No bank details available.</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When Not Loading and Data is there  */}
            {!loading &&
              bankData.map((bank, index) => (
                <tr key={index} className="fs-val-md">
                  <td>{bank.bankName ?? "N/A"}</td>
                  <td>{bank.accountNumber ?? "N/A"}</td>
                  <td>{bank.accountHolderName ?? "N/A"}</td>
                  <td>{bank.branchName ?? "N/A"}</td>
                  <td>{bank.ifsc ?? "N/A"}</td>
                  <td className="text-center">
                    {bank.isActive ? "Yes" : "No"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default VendorViewKycBank;
