import React from "react";
import { Modal, Button } from "react-bootstrap";

const VendorAccRemarksModal = ({ show, handleClose, remarks, referenceId }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Remarks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Reference ID:</strong> {referenceId}
        </p>
        <p className="fs-val-md">{remarks}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VendorAccRemarksModal;
