import {
  VendorMarginFilter,
  VendorMarginManageModal,
  VendorMarginTable,
} from "@sk/features";
import { VendorService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { useEffect, useRef, useState } from "react";

const BrandMargin = ({ vid, showAddMarginOpt = false }) => {
  const [data, setData] = useState([]);
  const [vendorMarginModal, setVendorMarginModal] = useState({ show: false });
  const [loading, setLoading] = useState(true);
  const origDataRef = useRef([]);

  useEffect(() => {
    const fetchMargin = async () => {
      try {
        setLoading(true);
        const r = await VendorService.getVendor(vid, {
          select: "margins",
        });

        const margins = r.resp.margins || [];
        const updatedMargins = await VendorService.getVendorMarginList(margins);

        setData(updatedMargins);
        origDataRef.current = [...updatedMargins];
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vendor margin:", error);
        setLoading(false);
      }
    };

    fetchMargin();
  }, []);

  const vendorMarginCallback = ({ action, data }) => {
    if (action === "edit") {
      setVendorMarginModal({ show: true, formData: data });
    } else {
      setVendorMarginModal({ show: false });
    }
  };

  const onFilterCb = ({ formData }) => {
    const filteredData = VendorService.vendorMarginFilterHandler(
      formData,
      origDataRef.current
    );
    setData(filteredData);
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col">
            <VendorMarginFilter callback={onFilterCb} />
          </div>
          {/* <div className="col-auto align-self-end">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setVendorMarginModal({ show: true })}
            >
              Add Margin
            </button>
          </div> */}
        </div>
      </AppCard>

      <AppCard>
        <VendorMarginTable
          data={data}
          loading={loading}
          callback={vendorMarginCallback}
        />
      </AppCard>

      <VendorMarginManageModal
        show={vendorMarginModal.show}
        callback={vendorMarginCallback}
        vendorId={vid}
        formData={vendorMarginModal.formData}
      />
    </>
  );
};

export default BrandMargin;
