import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { FranchiseService, NavService, VendorService } from "@sk/services";
import {
  AppCard,
  AuditLogTable,
  DateFormatter,
  KeyVal,
  NoDataFound,
  PageInfo,
  PageLoader,
  Tabs,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { viewVendor } from "../constantService";

import VendorViewBasic from "./components/VendorViewBasic";
import {
  AccountStatement,
  BrandMargin,
  Grn,
  IntakeSummary,
  KycDetails,
  Products,
  PurchaseOrder,
} from "./tabs";

const ViewVendor = () => {
  const [searchParams] = useSearchParams();

  const [display, setDisplay] = useState("loading");

  const [activeTab, setActiveTab] = useState({
    key: viewVendor.tabs[0].key,
  });

  // to Store Complete Data
  const [data, setData] = useState({});

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [warehouseName, setWarehouseName] = useState("");

  // to Fetch QueryString
  const query = useFetchUrlQueryString(searchParams);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    if (query.id) {
      loadVendorDetails(query.id);
      if (query.tab) {
        const tab = viewVendor.tabs.find((t) => t.key === query.tab);
        if (tab) {
          setActiveTab({ key: tab.key });
        }
      }
    } else {
      setDisplay("noDataFound");
    }
  }, [loadVendorDetails, query.id, query.tab]);

  // get Vendor Details
  const loadVendorDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await VendorService.getVendor(query.id);
    const d = r.resp || {};
    if (d._id) {
      // Adding basicData to d
      d.basicData = {
        _id: d._id,
        status: d.status,
        name: d.name,
        vendorCategory: d.vendorCategory,
        vendorType: d.vendorType,
        entity: d.entity,
        average_tat: d.average_tat,
        address: {
          line1: d?.address?.line1,
          line2: d?.address?.line2,
          state: d?.state,
          district: d?.district,
          city: d?.city,
          pincode: d?.pincode,
        },
        gst_no: d.gst_no,
        _contactDetails: d._contactDetails,
        mobile_no: d.mobile_no,
        geolocation: d.geolocation,
      };
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }

    // Setting Document details
    let docsList = [
      { label: "Pan Card", value: "panDocs" },
      { label: "Aadhar Card", value: "aadharDocs" },
      { label: "GST Document", value: "gstDocs" },
      { label: "Cancel Cheque", value: "cancelChequedocs" },
    ];
    docsList.forEach((e) => {
      let l = (d?.docs || []).filter((k) => {
        return k.name == e.label;
      });
      d[e.value] = l;
    });

    // Fetching WarehouseName
    loadWarehouseName(d);

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], viewVendor.attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == viewVendor.attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }

    setData(d);
  }, [attachAllData, loadWarehouseName, query.id, setAdditionalData]);

  const loadWarehouseName = useCallback(async (d) => {
    if (d?.warehouseData?.id) {
      let p = {
        select: "name,state,district,town",
        filter: { _id: { $in: d.warehouseData?.id } },
      };
      const r = await FranchiseService.getList(p);
      if (r.statusCode == 200) {
        setWarehouseName(r.resp.length ? r.resp[0] : {});
      } else {
        setWarehouseName({});
      }
    } else {
      setWarehouseName({});
    }
  }, []);

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const editVendor = () => {
    NavService.openInNewTab("/vendor/manage", { id: query.id });
  };

  return (
    <>
      <div className="row align-items-center py-2">
        <div className="col-10">
          <PageInfo
            title={`Vendor Details - #${data.basicData?._id || ""}`}
            breadcrumbs={viewVendor.breadcrumbs}
            navigate={navigate}
          />
        </div>
        {/* <div className="col-2 text-end">
          {display == "details" && (
            <button className="btn  btn-primary fs-val-md" onClick={editVendor}>
              Edit Vendor
            </button>
          )}
        </div> */}
      </div>

      {display == "loading" && <PageLoader />}
      {display == "noDataFound" && (
        <NoDataFound> No Vendor Details Found</NoDataFound>
      )}
      {display == "details" && (
        <>
          <div className="row g-2">
            <div className="col-12">
              <VendorViewBasic
                data={data.basicData}
                warehouseName={warehouseName}
              />
            </div>
          </div>

          <div className="mb-4">
            <Tabs
              data={viewVendor.tabs}
              activeTab={activeTab}
              callback={tabCb}
              template={4}
              shadow={true}
            />
          </div>

          <div>
            {activeTab.key == "kyc" && <KycDetails data={data} />}

            {/* Brand Margin */}
            {activeTab.key == "brandMargin" && (
              <BrandMargin
                margins={data.margins}
                vid={data._id}
                callback={init}
                showAddMarginOpt={true}
              />
            )}
            {activeTab.key == "intakeSummary" && (
              <IntakeSummary vendorId={data._id} />
            )}
            {/* Product */}
            {activeTab.key == "product" && <Products vendorId={data._id} />}
            {/* Account Statement */}
            {activeTab.key == "account" && (
              <AccountStatement vendorId={data._id} />
            )}
            {activeTab.key == "po" && <PurchaseOrder vendorId={data._id} />}
            {activeTab.key == "grn" && <Grn vendorId={data._id} />}

            {/* Linked Warehouse
              {activeTab.key == "warehouse" && (
                <LinkedWarehouse whIds={data?.whIds || []} />
              )} */}

            {/* Audit Log */}
            {activeTab.key == "auditLog" && (
              <>
                <AppCard>
                  <div className="row g-2 fs-val-md">
                    <div className="col">
                      <KeyVal
                        label="Created By"
                        labelCol="col-auto"
                        contentCol="col"
                        noBottomSpace={true}
                      >
                        : {data._createdBy?.name || "N/A"}
                      </KeyVal>
                    </div>
                    <div className="col">
                      <KeyVal
                        label="Created On"
                        labelCol="col-auto"
                        contentCol="col"
                        noBottomSpace={true}
                      >
                        : <DateFormatter date={data.createdAt} />
                      </KeyVal>
                    </div>
                    {data.lastUpdated ? (
                      <>
                        <div className="col">
                          <KeyVal
                            label="Updated By"
                            labelCol="col-auto"
                            contentCol="col"
                            noBottomSpace={true}
                          >
                            : {data._lastUpdatedBy?.name || "N/A"}
                          </KeyVal>
                        </div>
                        <div className="col">
                          <KeyVal
                            label="Updated On"
                            labelCol="col-auto"
                            contentCol="col"
                            noBottomSpace={true}
                          >
                            : <DateFormatter date={data.lastUpdated} />
                          </KeyVal>
                        </div>
                      </>
                    ) : null}
                  </div>
                </AppCard>
                <AuditLogTable
                  entityType="Vendor"
                  entityId={data._id}
                  withCard={true}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewVendor;
