import {
  Amount,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo, useCallback, useRef, useState } from "react";
import ViewProductModal from "../modals/ViewProductModal";
import classNames from "classnames";
import { NavService } from "@sk/services";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "PO ID", width: "10%" },
  { label: "Products", width: "15%" },
  { label: "Requested Qty", width: "10%" },
  { label: "Invoice Qty", width: "10%" },
  { label: "Value", width: "10%" },
  { label: "Status", width: "10%" },
  { label: "Created On", width: "15%" },
  { label: "Expected On", width: "15%" },
];

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [showProductModal, setShowProductModal] = useState(false);

  const productModalRef = useRef({ data: [] });

  const openProductModal = useCallback((products, poId) => {
    productModalRef.current.data = products;
    productModalRef.current.poId = poId; // Pass poId to the modal
    setShowProductModal(true);
  }, []);

  const closeProductModal = useCallback(() => {
    productModalRef.current.data = [];
    setShowProductModal(false);
  }, []);

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
        className="mb-3"
      />
      <div style={tableContainerStyle} className="mb-3">
        <table className="table table-sm">
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            noBg={true}
            isSticky={true}
          />

          {/* Table Body */}
          <tbody>
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* When No loading */}
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Purchase Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {/* When Not Loading and Data is there  */}
            {!loading &&
              data.map((x, index) => (
                <tr
                  key={x._id}
                  className={classNames("fs-val-md", {
                    "danger-row":
                      x._isDelayed || x.status === "Partially Inwarded",
                  })}
                >
                  {/* Serial Number */}
                  <td>{paginationConfig.startSlNo + index}</td>

                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={() =>
                        NavService.openInNewTab("/purchase-order/view", {
                          id: x._id,
                        })
                      }
                    >
                      {x._id}
                    </BtnLink>
                  </td>
                  <td>
                    <button
                      className="btn btn-link fs-val-md text-dark text-start p-0"
                      onClick={() => openProductModal(x.products, x._id)} // Pass poId here
                    >
                      {x.products.length} Products
                    </button>
                  </td>

                  <td>{x.totalQty}</td>

                  <td>{x.totalInvoicedQty}</td>

                  <td>
                    <Amount value={x.poValue} />
                  </td>

                  <td>
                    <HighlightText type={x._statusType} template={2}>
                      {x.status}
                    </HighlightText>
                  </td>

                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>

                  <td>
                    <DateFormatter date={x.expectedAt} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />

      <ViewProductModal
        closeModalCb={closeProductModal}
        show={showProductModal}
        data={productModalRef.current.data}
        poId={productModalRef.current.poId} // Pass poId to the modal
      />
    </>
  );
};

export default memo(Table);
