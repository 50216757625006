import { useAttachAdditionalData } from "@sk/hooks";
import { GrnService } from "@sk/services";
import { HighlightText } from "@sk/uis";
import {
  Amount,
  DateFormatter,
  PageLoader,
  Spinner,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Ref ID" },
  { label: "Type" },
  { label: "Amount" },
  { label: "Created On" },
  { label: "Modified On" },
  { label: "Status" },
  { label: "Remarks" },
  { label: "Document" },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "65%",
  },
};

const ViewAutoDebitAdjustmentModal = ({ show, closeModalCb, id }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const getAutoDebitAdjustList = useCallback(async () => {
    setLoading(true);
    const r = await GrnService.getAutoDebitAdjustList(id);

    const d = r.resp || [];

    d.map((x) => {
      x.userLoading = true;
      return x;
    });

    setData(d);

    setLoading(false);

    let tmp = [];
    // Attach User Info

    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
  }, [attachAllData, id, setAdditionalData]);

  useEffect(() => {
    if (show && id) {
      getAutoDebitAdjustList(id);
    }
  }, [show, id, getAutoDebitAdjustList]);

  console.log(data);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          <Offcanvas.Title>
            <div className="page-title">
              Auto /Manual Debit Adjustment : #{id}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <table className="table table-bordered table-sm">
                    <TableHeader data={headers} />
                    <tbody>
                      {/* No Data Found */}
                      {data.length == 0 && (
                        <tr>
                          <td
                            colSpan={headers.length}
                            className="fs-val-sm text-center"
                          >
                            No Data Found
                          </td>
                        </tr>
                      )}
                      {/* No  DAta */}
                      {data.length > 0 &&
                        data.map((item, i) => (
                          <tr key={i} className="fs-val-sm">
                            <td className="text-center">{i + 1}</td>
                            <td>{item.refId}</td>
                            <td>{item.type}</td>
                            <td>
                              <Amount value={item.totalAmount} />
                            </td>

                            <td>
                              <div>
                                Created At :
                                <DateFormatter
                                  date={item.createdAt}
                                  format="dd MMM yyyy"
                                />
                              </div>

                              <div>
                                {" "}
                                Created By :{" "}
                                {item.userLoading ? (
                                  <Spinner type="dots" />
                                ) : (
                                  item._createdBy.name
                                )}{" "}
                              </div>
                            </td>

                            <td>
                              <DateFormatter
                                date={item.lastUpdated}
                                format="dd MMM yyyy"
                              />
                            </td>

                            <td>
                              <HighlightText status={item.status} />
                            </td>

                            <td>{item.remarks ?? "N/A"}</td>
                            <td>{item.docs ?? "N/A"}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewAutoDebitAdjustmentModal;
