import { useAttachAdditionalData } from "@sk/hooks";
import { PoService } from "@sk/services";
import {
  Amount,
  PageLoader,
  TableHeader,
  Toaster,
  Spinner,
  AppCard,
} from "@sk/uis"; // Added AppCard
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

// Canvas Style
const style = {
  offCanvasStyle: {
    width: "65%",
  },
};

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Product Name" },
  { label: "MRP", isCentered: true },
  { label: "Qty", isCentered: true },
  { label: "Product Gst %", isCentered: true },
];

const attachAdditionalDataConfig = [
  {
    key: "productId",
    api: "product",
    loadingKey: "productLoading",
    dataKey: "_product",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name,tax",
    }),
  },
];

const ViewPoProductGstModal = ({ show, closeModalCb, id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Added searchTerm state
  const [filteredData, setFilteredData] = useState([]); // Added filteredData state

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const GetPoGstDetails = useCallback(async () => {
    setLoading(true);
    const r = await PoService.getPoById(id);

    const d = r.resp || {};
    setData(
      d.products.map((x) => {
        x.productLoading = true;
        return x;
      })
    );
    setLoading(false);

    if (d._id) {
      let tmp = [];
      // Attach User Info

      setAdditionalData(d.products, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d.products, tmp)]);
        }
      });
    } else {
      Toaster.error("Failed to fetch data, please try again");
      closeModalCb();
    }
  }, [attachAllData, closeModalCb, id, setAdditionalData]);

  useEffect(() => {
    if (show && id) {
      GetPoGstDetails(id);
    }
  }, [show, id, GetPoGstDetails]);

  useEffect(() => {
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
    setFilteredData(
      sortedData.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.productId &&
            item.productId.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  }, [data, searchTerm]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title">PO Product GST Details: #{id}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <AppCard>
              <div className="d-flex justify-content-between mb-3">
                <div className="fs-val-md align-self-center">
                  Total Products: {filteredData.length}
                </div>
                <input
                  type="text"
                  placeholder="Search by name or ID"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control w-50"
                />
              </div>
              {loading ? (
                <PageLoader />
              ) : (
                <table className="table table-sm">
                  <TableHeader data={headers} noBg={true} />
                  <tbody>
                    {/* No Data Found */}
                    {filteredData.length == 0 && (
                      <tr>
                        <td
                          colSpan={headers.length}
                          className="fs-val-sm text-center"
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                    {/* Data */}
                    {filteredData.length > 0 &&
                      filteredData.map((item, i) => (
                        <tr key={i} className="fs-val-sm">
                          <td className="text-center">{i + 1}</td>
                          <td>
                            <div className="mb-1">{item.name}</div>
                            <div className="text-muted">
                              ID: {item.productId}
                            </div>
                          </td>
                          <td className="text-center">
                            <Amount value={item.mrp} />
                          </td>
                          <td className="text-center">
                            {item.quantity.requested}
                          </td>
                          <td className="text-center">
                            {item.productLoading ? (
                              <Spinner type="dots" />
                            ) : (
                              item._product.tax + "%"
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </AppCard>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewPoProductGstModal;
