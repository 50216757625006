import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  PageInfo,
  SkeletonLoader,
  Spinner,
  TextInput,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PoService, VendorService } from "@sk/services";
import produce from "immer";
import { debounce, escapeRegExp } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { dashboardView } from "../constantService";
import styles from "./dashboard.module.scss";

const breadcrumb = dashboardView.breadcrumb;

const defaultSummaryCardOne = dashboardView.summaryCardOneData;

const defaultSummaryCardTwo = dashboardView.summaryCardTwoData;

const defaultVendorDetails = dashboardView.vendorDetails;

const orderHeader = dashboardView.poOrderTableHeader;

const paymentHeader = dashboardView.poPaymentTableHeader;

const alphabetSearchArray = dashboardView.alphabetSearchArray;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

// const recentPOData = [
//   { name: "Benglaluru Traders", status: "Payment Pending", value: 16_500 },
//   { name: "Benglaluru Traders", status: "Payment Pending", value: 16_500 },
//   { name: "Benglaluru Traders", status: "Payment Pending", value: 16_500 },
//   {
//     name: "SNK Foods",
//     status: "Pending Bill Matching",
//     value: 16_500,
//   },
//   {
//     name: "SNK Food",
//     status: "Pending Bill Matching",
//     value: 16_500,
//   },
// ];
const recentPOPayment = [
  { name: "Benglaluru Traders", date: new Date(), value: 16_500 },
  { name: "Benglaluru Traders", date: new Date(), value: 16_500 },
  { name: "Benglaluru Traders", date: new Date(), value: 16_500 },
  {
    name: "SNK Foods",
    date: new Date(),
    value: 16_500,
  },
  {
    name: "SNK Food",
    date: new Date(),
    value: 16_500,
  },
];

const getVendorData = async (params = {}) => {
  const r = await VendorService.getVendors(params);

  return Array.isArray(r.resp) ? r.resp : [];
};

const getPoData = async (params = {}) => {
  const r = await PoService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getVendorCount = async (params = {}) => {
  const p = { ...params };

  delete p.count;
  delete p.page;

  const r = await VendorService.getCount(params);
  return r.statusCode == 200 ? r.resp : 0;
};

const prepareFilterParams = (filter = {}, sort = {}) => {
  const p = {
    page: 1,
    count: 8,
    filter: {},
  };

  const vendor = filter?.vendor?.trim();
  const vendorRegex = { $regex: "^" + escapeRegExp(vendor), $options: "gi" };
  if (vendor) {
    p.filter = {
      $or: [{ _id: vendorRegex }, { name: vendorRegex }],
    };
  }

  // Alpha
  const alpha = filter?.alpha?.trim();
  const alphaRegex = { $regex: "^" + escapeRegExp(alpha), $options: "gi" };
  if (alpha) {
    p.filter = {
      $or: [{ _id: alphaRegex }, { name: alphaRegex }],
    };
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  return p;
};

const DashboardVendor = () => {
  const router = useNavigate();

  const vendorFilterDataRef = useRef({});

  const sortRef = useRef({ ...defaultSortOpt });

  const [vendorData, setVendorData] = useState({
    data: [],
    loading: true,
    count: 0,
    loadingTotalRecords: true,
  });

  const [poData, setPoData] = useState({
    data: [],
    loading: true,
  });

  const { register, getValues, setValue, control } = useForm({
    defaultValues: {
      vendor: "",
      alpha: "",
    },
  });

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(vendorFilterDataRef.current, sortRef.current);
  }, []);

  const loadVendorList = useCallback(async () => {
    const params = getFilterParams();
    // setting Loading State to true
    setVendorData(
      produce((draft) => {
        draft.loading = true;
      })
    );

    const d = await getVendorData(params);

    // Setting Api Response and loading State
    setVendorData(
      produce((draft) => {
        draft.loading = false;
        draft.data = d;
      })
    );
  }, [getFilterParams]);

  const loadVendorCount = useCallback(async () => {
    const params = getFilterParams();

    // Setting Vendor Count Loading State
    setVendorData(
      produce((draft) => {
        draft.loadingTotalRecords = true;
      })
    );
    const count = await getVendorCount(params);

    setVendorData(
      produce((draft) => {
        draft.loadingTotalRecords = false;
        draft.count = count;
      })
    );
  }, [getFilterParams]);

  const applyVendorFilter = useCallback(() => {
    loadVendorList();
    loadVendorCount();
  }, [loadVendorCount, loadVendorList]);

  const alphabetClickCb = useCallback(
    (x) => {
      setValue("alpha", x);
      setValue("vendor", "");

      vendorFilterDataRef.current = { ...getValues() };
      applyVendorFilter();
    },
    [setValue, getValues, applyVendorFilter]
  );

  const alpha = useWatch({
    control,
    name: "alpha",
  });

  const onVendorSearch = useCallback(
    debounce(() => {
      setValue("alpha", "");
      vendorFilterDataRef.current = { ...getValues() };
      applyVendorFilter();
    }, 700),
    [setValue, getValues, applyVendorFilter]
  );

  const loadPOData = useCallback(async () => {
    // Setting Loading State To True
    setPoData(
      produce((draft) => {
        draft.loading = true;
      })
    );

    let d = await getPoData({
      sort: {
        createdAt: -1,
      },
      page: 1,
      count: 5,
    });

    // Setting Data and Loading State
    setPoData(
      produce((draft) => {
        draft.loading = false;
        draft.data = d;
      })
    );
  }, []);

  const init = useCallback(() => {
    applyVendorFilter();
    loadPOData();
  }, [applyVendorFilter, loadPOData]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <PageInfo title="Dashboard" breadcrumbs={breadcrumb} navigate={router} />

      {/* Date Range */}
      <div className="row mb-2">
        <div className="col-6 fs-val-md">
          <i className="bi bi-calendar3 me-1 text-danger"></i>
          <span className="fw-semibold ">Last 30 days : </span>

          {/* Date Range */}
          <span>
            <DateFormatter date={new Date()} format="dd MMM yyyy" /> -
            <DateFormatter date={new Date()} format="dd MMM yyyy" />
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-9">
          {/*Summary Cards 1  */}

          <div className="row mb-3">
            {defaultSummaryCardOne.map((card) => (
              <div className="col-3" key={card.label}>
                <div
                  className={classNames(
                    "rounded-2 shadow-sm",
                    styles[card.bgColor]
                  )}
                >
                  <div className={"card-title px-3 py-2"}>{card.label}</div>

                  {/* Card Body */}
                  <div className={classNames(styles[card.borderColor])}>
                    <div className="row align-items-center px-3 py-2">
                      <div className="col-9  font-20 fw-semibold">
                        {card.loading ? <Spinner type="dots" /> : card.value}
                      </div>
                      <div className="col-3">
                        <img src={card.icon} alt={card.icon} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Summary Card 2 */}
          <div className="row mb-3">
            {defaultSummaryCardTwo.map((card) => (
              <div className="col-3" key={card.label}>
                <div className={classNames("rounded-2 shadow-sm bg-white")}>
                  {/* Card Body */}
                  <div className={classNames(styles[card.borderColor])}>
                    <div className="row align-items-center px-3 py-2">
                      <div className="col-9">
                        <div className="card-title text-muted">
                          {card.label}
                        </div>
                        <div className="font-22 fw-semibold">
                          {card.loading ? (
                            <Spinner type="dots" />
                          ) : card.type == "amount" ? (
                            <Amount value={card.value} />
                          ) : (
                            card.value
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <img src={card.icon} alt={card.label} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Row 3 */}
          <div className="row mb-3">
            {/* PO month Line Chart */}
            <div className="col-6">
              <AppCard title="Purchase Orders Moths Till Date">
                <div
                  style={{
                    height: "15.3rem",
                  }}
                ></div>
              </AppCard>
            </div>

            {/* Top Vendor  */}
            <div className="col-6">
              <AppCard title="Top Vendors">
                {defaultVendorDetails.map((card, index) => (
                  <div
                    key={index}
                    className={classNames("py-2", {
                      "border-bottom": index <= defaultVendorDetails.length - 2,
                    })}
                  >
                    <div className="row align-items-center">
                      <div className="col-1">{index + 1}</div>
                      <div className="col-8">
                        <div className="text-primary fs-val-md">
                          {card.vendorDetails.name}
                          <span className="ms-2 border fs-val-sm p-1">
                            <i className="bi bi-star-fill text-warning"></i>
                            {card.data.star}
                          </span>
                        </div>
                        <div className="fs-val-sm text-muted">
                          {card.vendorDetails.type} , {card.vendorDetails.state}
                          / {card.vendorDetails.district} /
                          {card.vendorDetails.city}
                        </div>
                      </div>
                      <div className="col-3">
                        <i
                          className={classNames(
                            [card.data.icon],
                            "me-1",
                            card.data.type == "credit"
                              ? "text-primary"
                              : "text-danger"
                          )}
                        ></i>
                        <span className="font-16 fw-semibold">
                          <Amount value={card.data.value} />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </AppCard>
            </div>
          </div>

          {/* Row 4 */}
          <div className="row mb-3">
            {/* Recent Purchase Order */}
            <div className="col-6">
              <AppCard title="Recent Purchase Order">
                <div style={{ height: "34.5vh" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {orderHeader.map((th) => (
                          <th
                            key={th + "Purchase"}
                            className="text-muted fs-val-md"
                          >
                            {th.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* While Loading*/}
                      {poData.loading && (
                        <TableSkeletonLoader
                          height={20}
                          rows={5}
                          cols={orderHeader.length}
                        />
                      )}
                      {/* When data is loading */}
                      {!poData.loading &&
                        poData.data.map((data) => (
                          <tr key={data.name + "PO"} className="fs-val-md">
                            <td className="text-primary fw-semibold text-truncate overflow-hidden">
                              {data.contact.vendorName}
                            </td>
                            <td>
                              <HighlightText status={data.status} />
                            </td>
                            <td>
                              <Amount value={data.poValue} />
                            </td>
                          </tr>
                        ))}

                      {/* When data is not there */}
                      {!poData.loading && poData.data.length == 0 && (
                        <tr>
                          <td
                            colSpan={orderHeader.length}
                            className="text-center"
                          >
                            No Vendor Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </AppCard>
            </div>

            {/* Recent Payment */}
            <div className="col-6">
              <AppCard title="Recent Payment">
                <table className="table">
                  <thead>
                    <tr>
                      {paymentHeader.map((th) => (
                        <th
                          key={th + "Purchase"}
                          className="text-muted fs-val-md"
                        >
                          {th.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {recentPOPayment.map((data) => (
                      <tr key={data.name + "PO"} className="fs-val-md">
                        <td className="text-primary fw-semibold">
                          {data.name}
                        </td>
                        <td>
                          <DateFormatter
                            date={data.date}
                            format="dd MMM yyyy"
                          />
                        </td>
                        <td>
                          <Amount value={data.value} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AppCard>
            </div>

            {/* Brand Images  */}
            <div className="col-12">
              <AppCard title="Top Brand">
                <div
                  style={{
                    height: "6rem",
                  }}
                ></div>
              </AppCard>
            </div>
          </div>
        </div>

        {/* Side Bar */}
        <div className="col-3">
          {/* Order Overview */}
          <AppCard title="Orders Overview">
            <div
              style={{
                height: "15.3rem",
              }}
            ></div>
          </AppCard>

          {/* Vendor List */}

          <AppCard>
            <div className="border-bottom p-2 fs-val-lg text-muted fw-semibold">
              Total Vendor :
              {vendorData.loadingTotalRecords ? (
                <Spinner type="dots" />
              ) : (
                <span> ({vendorData.count})</span>
              )}
            </div>
            <div>
              <TextInput
                name="vendor"
                placeholder="Search By Vendor Name / ID"
                register={register}
                callback={onVendorSearch}
                groupContent={<i className="bi bi-search px-2"></i>}
              />
            </div>
            {/* Vendor List */}
            <div
              className={classNames("row", styles["scroll-contain"])}
              style={{
                height: "81vh",
                overflowY: "scroll",
              }}
            >
              <div className="col-10">
                {/* Vendor Item */}
                {!vendorData.loading &&
                  vendorData.data.length > 0 &&
                  vendorData.data.map((d) => (
                    <div className="border-bottom py-2" key={d._id + "vendor"}>
                      <div className="text-primary mb-2">{d.name}</div>
                      <div className="fs-val-sm text-muted mb-1">
                        {d.vendorType} , {d?.communication?.state ?? "N/A"}/
                        {d?.communication?.district ?? "N/A"} /
                        {d?.communication?.city ?? "N/A"}
                      </div>
                      <div className="fs-val-xs text-muted">
                        <span>
                          Last Po Date : <DateFormatter date={new Date()} />
                        </span>
                        <span className="ms-2">
                          No Of Brand :{" "}
                          {(d.margins || []).reduce((acc, cur) => {
                            return cur.brand ? acc + 1 : acc;
                          }, 0)}
                        </span>
                      </div>
                    </div>
                  ))}

                {/* When No Data */}
                {!vendorData.loading && vendorData.data.length == 0 && (
                  <div className="text-center">No Vendors found</div>
                )}

                {/* when Loading */}
                {vendorData.loading && (
                  <SkeletonLoader height={53} count={11} />
                )}
              </div>
              <div className="col-2">
                {alphabetSearchArray.map((x) => (
                  <div key={x.label} className="text-center">
                    <span
                      role={"button"}
                      tabIndex={-1}
                      className={classNames(
                        "d-inline-block",
                        alpha == x.label && "text-primary fw-semibold"
                      )}
                      onClick={() => alphabetClickCb(x.label)}
                    >
                      {x.label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </AppCard>
        </div>
      </div>
    </>
  );
};

export default DashboardVendor;
