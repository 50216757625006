import { UtilityService } from "@sk/services";
import {
  BooleanCheckboxInput,
  EntitySearchInput,
  HighlightText,
  KeyVal,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { each } from "lodash";
import { useCallback } from "react";
import { Accordion } from "react-bootstrap";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../../../constantService";

const bankValidationSchema = yup.object({
  bankDetailsForm: yup.object({
    bank: yup.array().required("Select Bank"),
    bankName: yup.string(),
    accountNumber: yup
      .number()
      .nullable()
      .transform((v) => (isFinite(v) ? v : null))
      .label("Account Number")
      .required(),
    accountHolderName: yup.string().label("Account Holder Name").required(),
    branchName: yup.string().trim().label("Branch Name").required(),
    bankId: yup.string(),
    ifsc: yup
      .string()
      .trim()
      .matches(/^[A-Z a-z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format")
      .label("IFSC")
      .required(),
    isActive: yup.boolean().default(false),
    editIndex: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v)),
  }),
});

const BankDetails = () => {
  const {
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const bankDetailsList = useWatch({
    control,
    name: "bankDetails",
  });

  const editIndex = useWatch({
    control,
    name: "bankDetailsForm.editIndex",
  });

  // const deleteBankDetails = (index) => {
  //   const k = [...bankDetailsList];
  //   k.splice(index, 1);
  //   setValue("bankDetails", k);
  // };

  // ---------Edit Bank Details--------
  const editBankDetails = async (index) => {
    const d = bankDetailsList[index] || {};
    each(manageView.defaultBankDetailsFormData, (x, k) => {
      if (k == "editIndex") {
        setValue("bankDetailsForm.editIndex", index);
      } else {
        setValue("bankDetailsForm." + k, d[k]);
      }
    });
    const r = await UtilityService.getBankList({ filter: { _id: d.bankId } });
    if (Array.isArray(r.resp)) {
      let bnk = [
        { label: r.resp[0].name + "(" + r.resp[0]._id + ")", value: r.resp[0] },
      ];
      setValue("bankDetailsForm.bank", bnk);
    }
  };

  const onBankSelect = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e || {});
        setValue("bankDetailsForm.bank", e);
        setValue("bankDetailsForm.bankId", e.length ? e[0].value._id : "");
        setValue("bankDetailsForm.bankName", e.length ? e[0].value.name : "");
      };
    },
    [setValue]
  );

  // ----------------------ADD FORM------------------------
  const addBankDetails = async () => {
    // attribute form validation
    let errMsg = "";
    try {
      await bankValidationSchema.validate({
        bankDetailsForm: getValues("bankDetailsForm"),
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let f = getValues("bankDetailsForm");

    const data = bankDetailsList.slice();

    // checking duplicate data in list
    let duplicate = data.find((k) => {
      return f.bankId == k?.bankId;
    });

    const formData = Object.assign({}, f);

    if (f.editIndex >= 0) {
      data.splice(f.editIndex, 1, formData);
    } else {
      if (duplicate?.name) {
        Toaster.error("Item already exists in List ");
        return;
      }
      data.push(formData);
    }

    setValue("bankDetails", data);
    resetInputValue();
  };

  const resetInputValue = () => {
    each(manageView.defaultBankDetailsFormData, (x, k) => {
      setValue("bankDetailsForm." + k, x);
    });
    setValue("bankDetailsForm.bank", []);
  };

  return (
    <>
      {bankDetailsList?.length > 0 && (
        <div className="row mb-4">
          <Accordion>
            {bankDetailsList.map((x, i) => (
              <Accordion.Item eventKey={i} as={"div"} key={i}>
                <Accordion.Header as={"div"}>
                  <div className="row">
                    <div className="col-12 fs-val-lg">
                      {x?.bankName}({x?.bankId})
                    </div>
                    <div className="col-6">
                      <span className="fs-val-sm text-muted">
                        Account Number :
                      </span>
                      <span className="fs-val-sm">{x?.accountNumber}</span>
                    </div>
                    <div className="col-6">
                      <span className="fs-val-sm text-muted">Ifsc Code :</span>
                      <span className="fs-val-sm">{x?.ifsc}</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-10">
                      <KeyVal label="Name">{x?.accountHolderName}</KeyVal>
                      <KeyVal label="Branch Name">{x?.branchName}</KeyVal>
                      <KeyVal label="Is Active">
                        <HighlightText status={x?.isActive ? "Yes" : "No"} />
                      </KeyVal>
                    </div>
                    <div className="col-2">
                      <span
                        className="btn btn-sm fs-val-sm btn-outline-warning"
                        role="button"
                        tabIndex={0}
                        onClick={() => editBankDetails(i)}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      )}

      {/* ATTRIBUTE  FORM FIELDS  */}
      <div className="row">
        {/* Name */}
        <div className="col-12">
          <Controller
            control={control}
            name="bankDetailsForm.bank"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type={"bank"}
                label="Search for Bank"
                placeholder="Search By bank Name/ID"
                isMandatory={true}
                value={value}
                callback={onBankSelect(onChange)}
                uid="category-search"
                isMultiple={false}
              />
            )}
          />
        </div>

        {/* Account Number */}
        <div className="col-12">
          <TextInput
            type="number"
            placeholder="Enter Account Number "
            label="Account Number"
            register={register}
            name="bankDetailsForm.accountNumber"
            error={errors?.bankDetailsForm?.accountNumber?.message}
            isMandatory={true}
          />
        </div>

        {/* Account Holder Name */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter Account Holder Name"
            label="Account Holder Name"
            register={register}
            name="bankDetailsForm.accountHolderName"
            error={errors?.bankDetailsForm?.accountHolderName?.message}
            isMandatory={true}
          />
        </div>

        {/* Branch Name */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter Branch Name"
            label="Branch Name"
            register={register}
            name="bankDetailsForm.branchName"
            error={errors?.bankDetailsForm?.branchName?.message}
            isMandatory={true}
          />
        </div>

        {/* IFSC */}
        <div className="col-12">
          <TextInput
            type="text"
            placeholder="Enter IFSC "
            label="IFSC"
            register={register}
            name="bankDetailsForm.ifsc"
            error={errors?.bankDetailsForm?.ifsc?.message}
            isMandatory={true}
          />
        </div>

        {/* Status Active */}
        <div className="col-6 align-self-center">
          <Controller
            name="bankDetailsForm.isActive"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                label="Is Active"
                name="sameAsOfficeAddress"
                isMandatory={false}
                callback={onChange}
                value={value}
                gap={0}
              />
            )}
          />
        </div>

        <div className="col-auto ms-auto text-end">
          {/* Button */}
          <button
            type="button"
            onClick={addBankDetails}
            className={classNames("btn  fs-val-md", {
              "btn-primary": !editIndex || editIndex == -1,
              "btn-success": editIndex >= 0,
            })}
          >
            {editIndex >= 0 ? "Update" : "Add"}
          </button>
          {editIndex >= 0 && (
            <button
              type="button"
              onClick={resetInputValue}
              className="btn-danger btn ms-2 fs-val-md"
            >
              <i className="bi bi-close me-2 "></i>
              Cancel
            </button>
          )}
        </div>
      </div>

      {/* 
      <div>
        <table className="table table-bordered table-sm">
          <TableHeader data={manageView.bankDetailsHeaders} />

          <tbody className="table-group-divider">
            {!bankDetailsList?.length ? (
              <tr>
                <td colSpan={manageView.bankDetailsHeaders.length}>
                  <div className="px-3">
                    <NoDataFound>No Data Found</NoDataFound>
                  </div>
                </td>
              </tr>
            ) : null}

            {(bankDetailsList || []).map((d, index) => (
              <tr key={index} className="fs-val-md">
                <td className="text-center p-2">{index + 1}</td>
                <td className="p-2">
                  {d?.bankName}({d?.bankId})
                </td>

                <td className="p-2">{d?.accountNumber}</td>
                <td className="p-2">{d?.accountHolderName}</td>
                <td className="p-2">{d?.branchName}</td>
                <td className="p-2">{d?.ifsc}</td>
                <td className="text-center p-2">
                  {d?.isActive ? "Yes" : "No"}
                </td>
                <td className="text-center  p-2">
                  <span
                    className="shadow-sm p-0 px-1 bg-white rounded  me-3"
                    onClick={() => deleteBankDetails(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <i className="bi bi-x  text-danger cursor-pointer"></i>
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => editBankDetails(index)}
                  >
                    <i className="bi bi-pencil-square  text-primary cursor-pointer"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default BankDetails;
