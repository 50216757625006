import { AuthService } from "@sk/services";
import {
  DateFormatter,
  DatePickerInput,
  EntitySearchInput,
  NoDataFound,
  RadioInput,
  SelectInput,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { each } from "lodash";
import { useCallback, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../../../constantService";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((e) => {
  return { label: e.name, value: e._id };
});
warehouseOptions.unshift({ label: "Select Warehouse", value: "" });

const brandMarginValidationSchema = yup.object({
  marginDetailsForm: yup.object({
    marginType: yup.string().trim().label("Margin Type").required(),
    brand: yup.string(),
    category: yup.string(),
    product: yup.string(),
    _brand: yup.array().when("marginType", {
      is: (marginType) => marginType == "Brand-Category",
      then: (schema) =>
        schema.min(1, "Brand is Required").label("Brand").required(),
    }),
    _category: yup.array().when("marginType", {
      is: (marginType) => marginType == "Brand-Category",
      then: (schema) =>
        schema.min(1, "Category is Required").label("Category").required(),
    }),
    _product: yup.array().when("marginType", {
      is: (marginType) => marginType == "Product",
      then: (schema) =>
        schema.min(1, "Product is Required").label("Product").required(),
    }),
    whId: yup.string(),
    margin: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v))
      .min(0, "Min Margin is 0")
      .max(100, "Max Margin should be 100")
      .required("Margin is Required"),
    weightage: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v)),
    start_date: yup
      .array()
      .min(1, "Start Date is Required")
      .label("Start Date")
      .required(),
    end_date: yup
      .array()
      .min(1, "End Date is Required")
      .label("End Date")
      .required(),
    editIndex: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v)),
  }),
});

const BrandMargin = ({ margin }) => {
  const {
    register,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const marginsList = useWatch({
    control,
    name: "margins",
  });

  const editIndex = useWatch({
    control,
    name: "marginDetailsForm.editIndex",
  });

  const deleteMarginDetails = (index) => {
    const k = marginsList;
    k.splice(index, 1);
    setValue("margins", k);
  };

  const onDateChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
    },
    []
  );

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(async () => {
    if (margin.length) {
      // preparing product,category ,brand  to display in Input
      let d = (margin || []).filter((e) => {
        e._product = e._productDetails._id
          ? [{ label: e._productDetails.name, value: e._productDetails._id }]
          : [];
        e._category = e?._categoryDetails._id
          ? [
              {
                label: e?._categoryDetails.name,
                value: e?._categoryDetails._id,
              },
            ]
          : [];
        e._brand = e?._brandDetails._id
          ? [{ label: e?._brandDetails.name, value: e?._brandDetails._id }]
          : [];
        let wh = AuthService.getLoggedInEmpWh().find((k) => k._id == e.whId);
        e.warehouse = wh?.name || "";
        return e;
      });

      setValue("margins", d);
    } else {
      setValue("margins", []);
    }

    await trigger("margins");
  }, [margin, setValue, trigger]);

  const onMarginTypeChange = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      setValue("marginDetailsForm.marginType", val);
      if (val == "Product") {
        setValue("marginDetailsForm.category", "");
        setValue("marginDetailsForm.brand", "");
        setValue("marginDetailsForm._category", []);
        setValue("marginDetailsForm._brand", []);
      } else {
        setValue("marginDetailsForm.product", "");
        setValue("marginDetailsForm._product", []);
      }
      await trigger("marginDetailsForm");
    },
    [setValue, trigger]
  );

  // ---------Edit Bank Details--------
  const editMarginDetails = async (index) => {
    const d = marginsList[index] || {};
    each(manageView.defaultBrandMarginDetailsFormData, (x, k) => {
      if (k == "editIndex") {
        setValue("marginDetailsForm.editIndex", index);
      } else {
        setValue("marginDetailsForm." + k, d[k]);
      }
    });
    setValue("marginDetailsForm.start_date", [d.start_date]);
    setValue("marginDetailsForm.end_date", [d.end_date]);
    await trigger("marginDetailsForm");
  };

  // ----------------------ADD FORM------------------------
  const addMarginDetails = async () => {
    // attribute form validation

    let errMsg = "";
    try {
      await brandMarginValidationSchema.validate({
        marginDetailsForm: getValues("marginDetailsForm"),
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let f = getValues("marginDetailsForm");

    // Setting Product , brand, Category id
    f.product = f._product?.length ? f._product[0].value._id : "";
    f.brand = f._brand?.length ? f._brand[0]?.value?._id : "";
    f.category = f._category?.length ? f._category[0]?.value?._id : "";

    const data = marginsList.slice();
    // checking duplicate data in list
    let duplicate = data.find((k) => {
      if (f.marginType == "Product") {
        return f.product == k?.product;
      } else {
        return f.brand == k?.brand && f.category == k.category;
      }
    });

    const formData = Object.assign({}, f);
    if (f.editIndex >= 0) {
      data.splice(f.editIndex, 1, formData);
    } else {
      if (duplicate?.name) {
        Toaster.error("Item already exists in List ");
        return;
      }
      data.push(formData);
    }

    setValue("margins", data);
    resetInputValue();
  };

  const resetInputValue = () => {
    each(manageView.defaultBrandMarginDetailsFormData, (x, k) => {
      setValue("marginDetailsForm." + k, x);
    });
  };

  return (
    <>
      {/*   FORM FIELDS  */}
      <div className="row">
        {/* Margin Type */}
        <div className="col-6 mb-3">
          <Controller
            name="marginDetailsForm.marginType"
            control={control}
            render={({ field: { value, onChange } }) => (
              <RadioInput
                label="Margin Type"
                register={register}
                value={value}
                onChange={onMarginTypeChange(onChange)}
                cols={6}
                name="marginDetailsForm.marginType"
                isMandatory={true}
                options={manageView.marginTypeOptions}
              />
            )}
          />
        </div>

        {getValues("marginDetailsForm.marginType") != "Product" ? (
          <>
            {/* Brand */}
            <div className="col-3 mb-3">
              <Controller
                control={control}
                name="marginDetailsForm._brand"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type={"brand"}
                    label="Search for Brand"
                    placeholder="Search By Brand Name/ID"
                    value={value}
                    callback={onChange}
                    isMandatory={true}
                    uid="brand-search"
                    isMultiple={false}
                  />
                )}
              />
            </div>

            {/* Category */}
            <div className="col-3 mb-3">
              <Controller
                control={control}
                name="marginDetailsForm._category"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type={"category"}
                    label="Search for Category"
                    placeholder="Search By Category Name/ID"
                    value={value}
                    callback={onChange}
                    isMandatory={true}
                    uid="category-search"
                    isMultiple={false}
                  />
                )}
              />
            </div>
          </>
        ) : (
          // Product
          <div className="col-6 mb-3">
            <Controller
              control={control}
              name="marginDetailsForm._product"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type={"product"}
                  label="Search for Product"
                  placeholder="Search By Product Name/ID"
                  value={value}
                  callback={onChange}
                  isMandatory={true}
                  uid="product-search"
                  isMultiple={false}
                />
              )}
            />
          </div>
        )}

        {/* Warehouse */}
        <div className="col-3 mb-3">
          <SelectInput
            type="text"
            label="Warehouse"
            register={register}
            name="marginDetailsForm.whId"
            error={errors?.marginDetailsForm?.whId?.message}
            options={warehouseOptions}
            isMandatory={false}
          />
        </div>

        {/* Margin */}
        <div className="col-1 mb-3">
          <TextInput
            type="number"
            placeholder="Margin (%)"
            label="Margin(%)"
            register={register}
            name="marginDetailsForm.margin"
            error={errors?.marginDetailsForm?.margin?.message}
            isMandatory={true}
          />
        </div>

        {/* Weightage */}
        <div className="col-1 mb-3">
          <TextInput
            type="number"
            placeholder="Weightage"
            label="Weightage"
            register={register}
            name="marginDetailsForm.weightage"
            error={errors?.marginDetailsForm?.weightage?.message}
            isMandatory={false}
          />
        </div>

        {/* Start Date */}
        <div className="col-2 mb-3">
          <label className="mb-1 fs-val-md">
            Start Date <span className="text-danger">*</span>
          </label>
          <Controller
            name="marginDetailsForm.start_date"
            control={control}
            error={errors?.marginDetailsForm?.start_date?.message}
            render={({ field: { value, onChange } }) => (
              <DatePickerInput
                placeholder="Select Date"
                value={[value]}
                inpChange={onDateChange(onChange)} // send value to hook form
                isMandatory={true}
              />
            )}
          />
        </div>

        {/* End Date */}
        <div className="col-2 mb-3">
          <label className="mb-1 fs-val-md">
            End Date <span className="text-danger">*</span>
          </label>
          <Controller
            name="marginDetailsForm.end_date"
            control={control}
            error={errors?.marginDetailsForm?.end_date?.message}
            render={({ field: { value, onChange } }) => (
              <DatePickerInput
                placeholder="Select Date"
                value={[value]}
                inpChange={onDateChange(onChange)} // send value to hook form
                isMandatory={true}
              />
            )}
          />
        </div>
        {/* Action */}
        <div className="col-auto ms-auto text-end">
          {/* Add / Update */}
          <button
            type="button"
            onClick={addMarginDetails}
            className={classNames({
              btn: true,
              "mt-2 mb-3 ": true,
              "btn-primary": !editIndex || editIndex == -1,
              "btn-success": editIndex >= 0,
            })}
          >
            {editIndex >= 0 ? null : <i className="bi bi-plus me-2 "></i>}
            {editIndex >= 0 ? "Update" : "Add Margin"}
          </button>

          {/* Cancel */}
          {editIndex >= 0 && (
            <button
              type="button"
              onClick={resetInputValue}
              className="btn-danger btn ms-2 mt-2 mb-3 btn-sm"
            >
              <i className="bi bi-close me-2 "></i>
              Cancel
            </button>
          )}
        </div>
      </div>
      <div className="mt-2">
        {/*  TABLE */}

        <table className="table table-bordered table-sm">
          <TableHeader data={manageView.brandMarginsHeaders} />
          {/* TABLE BODY */}
          <tbody className="table-group-divider">
            {!marginsList?.length ? (
              <tr>
                <td colSpan={manageView.brandMarginsHeaders.length}>
                  <div className="px-3">
                    <NoDataFound>No Data Found, Add Margin Details</NoDataFound>
                  </div>
                </td>
              </tr>
            ) : null}
            {(marginsList || []).map((d, index) => (
              <tr key={index} className="fs-val-md">
                <td className="text-center p-2">{index + 1}</td>
                <td className="p-2">
                  {!d?.brand?.length && !d?.category?.length ? (
                    "N/A"
                  ) : (
                    <>
                      {d?._brand?.length ? d?._brand[0]?.label : ""} -
                      {d?._category?.length ? d?._category[0]?.label : ""}
                    </>
                  )}
                </td>
                <td className="p-2">
                  {d?._product?.length ? d?._product[0]?.label : "N/A"}
                </td>
                <td className="p-2">{d?.warehouse || d?.whId || "N/A"}</td>
                <td className="p-2 text-center">{d?.marginType}</td>
                <td className="p-2 text-center">{d?.margin}</td>
                <td className="p-2 text-center">{d?.weightage || "N/A"}</td>
                <td className="p-2 text-center">
                  <DateFormatter date={d?.start_date} format={"dd MMM yyyy"} />
                </td>
                <td className="p-2 text-center">
                  <DateFormatter date={d?.end_date} format={"dd MMM yyyy"} />
                </td>
                <td className="text-center  p-2">
                  <span
                    className="shadow-sm p-0 px-1 bg-white rounded  me-3"
                    onClick={() => deleteMarginDetails(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <i className="bi bi-x  text-danger cursor-pointer"></i>
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => editMarginDetails(index)}
                  >
                    <i className="bi bi-pencil-square  text-primary cursor-pointer"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BrandMargin;
