import { Amount, TableHeader, AppCard, BtnLink } from "@sk/uis"; // Added AppCard
import { Offcanvas } from "react-bootstrap";
import { useState, useEffect } from "react"; // Added useState and useEffect
import { NavService } from "@sk/services"; // Added NavService

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "65%",
  },
};

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Product Name", width: "25%" },
  { label: "MRP", width: "8%", isCentered: true },
  { label: "Unit Price", width: "8%", isCentered: true },
  { label: "Case Qty", width: "10%", isCentered: true },
  { label: "InnerCase Qty", width: "10%", isCentered: true },
  { label: "Requested Qty", width: "10%", isCentered: true },
  { label: "Total", width: "10%", isCentered: true },
];

const ViewProductModal = ({ show, closeModalCb, data = [], poId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
    setFilteredData(
      sortedData.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.productId.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);

  const viewProduct = (productId) => {
    NavService.openInNewTab("/cms/product/view", { id: productId });
  };

  const viewPo = (poId) => {
    NavService.openInNewTab("/purchase-order/view", { id: poId });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header closeButton closeVariant="white">
          <Offcanvas.Title>
            <div className="page-title">
              Products for PO ID: {poId}
              <BtnLink onClick={() => viewPo(poId)} className="fs-val-sm ms-2">
                View More Details
              </BtnLink>
            </div>
            {/* Display poId */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {show ? (
            <AppCard>
              <div className="d-flex justify-content-between mb-3">
                <div className="fs-val-md align-self-center">
                  Total Products: {filteredData.length}
                </div>
                <input
                  type="text"
                  placeholder="Search by name or ID"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control w-50"
                />
              </div>
              <table className="table table-sm">
                <TableHeader data={headers} noBg />
                <tbody>
                  {/* No Data Found */}
                  {filteredData.length == 0 && (
                    <tr>
                      <td
                        colSpan={headers.length}
                        className="fs-val-sm text-center"
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                  {/* Data */}
                  {filteredData.length > 0 &&
                    filteredData.map((item, i) => (
                      <tr key={i} className="fs-val-sm">
                        <td>{i + 1}</td>
                        <td>
                          <BtnLink
                            onClick={() => viewProduct(item.productId)}
                            className="fs-val-sm mb-1"
                          >
                            {item.name}
                          </BtnLink>
                          <div className="text-muted">ID: {item.productId}</div>
                        </td>
                        <td className="text-center">
                          <Amount value={item.mrp} />
                        </td>
                        <td className="text-center">
                          <Amount value={item.unitPrice} />
                        </td>
                        <td className="text-center">{item.caseQty}</td>
                        <td className="text-center">{item.innerPackQty}</td>
                        <td className="text-center">
                          {item.quantity.requested}
                        </td>
                        <td className="text-center">
                          <Amount value={item.total} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </AppCard>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewProductModal;
