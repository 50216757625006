import { FranchiseService } from "@sk/services";
import { SkeletonLoader, Toaster } from "@sk/uis";
import classNames from "classnames";
import { memo, useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const LinkWarehouse = () => {
  const { setValue, getValues, control } = useFormContext();

  const [loading, setLoading] = useState(false);

  const getAllWarehouses = useCallback(async () => {
    setLoading(true);
    const whIds = getValues("whIds");
    const r = await FranchiseService.getFranchises({
      select: "name,id,status",
      filter: {
        "sk_franchise_details.franchise_type": "/WMF/",
        status: "Approved",
      },
    });
    setLoading(false);
    if (r.statusCode != 200) {
      Toaster.error("Unable to load please try later");
      return;
    }
    const list = r.resp.map((x) => ({
      label: x.name,
      value: x._id,
      selected: whIds.find((wh) => x._id == wh) ? true : false,
    }));

    setValue("warehouseList", list);
  }, [setValue, getValues]);

  useEffect(() => {
    const list = getValues("warehouseList");
    if (!list.length) {
      getAllWarehouses();
    }
  }, [getAllWarehouses, getValues]);

  const warehouses = useWatch({
    control,
    name: "warehouseList",
  });

  const OnWarehouseClick = (i) => {
    const list = getValues("warehouseList");
    list[i].selected = !list[i].selected;
    setValue("warehouseList", list);

    // Setting WhIds
    const whIds = list.filter((x) => x.selected).map((x) => x.value);
    setValue("whIds", whIds);
  };

  return (
    <>
      {!loading ? (
        warehouses.map((x, i) => (
          <div
            role="button"
            tabIndex={-1}
            key={"block-" + i}
            onClick={() => OnWarehouseClick(i)}
            className={classNames(
              " d-inline-block text-dark p-2 m-2 fs-val-md",
              {
                "bg-light-blue-300": x.selected,
                "border border-grey": !x.selected,
              }
            )}
          >
            {x.label}
            {x.selected && (
              <span className="border rounded-2 ms-1 bg-light">
                <i className="bi bi-x fs-val-lg p-1"></i>
              </span>
            )}
          </div>
        ))
      ) : (
        <div className="row">
          {Array(10)
            .fill(-1)
            .map((x, i) => (
              <div className="col-3" key={"loader" + i}>
                <SkeletonLoader height={40} />
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default memo(LinkWarehouse);
