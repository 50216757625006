import { SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { viewVendor } from "../../../../constantService";

const defaultFilterFormData = viewVendor.tabData.products.filterFormData;

const Filter = ({ callback }) => {
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: defaultFilterFormData,
  });

  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const onSearch = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 800),
    []
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-4">
          <TextInput
            name="search"
            register={register}
            label="Search For Product"
            placeholder="Search by Id/Name"
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="status"
            register={register}
            label="Status"
            callback={onSearch}
            options={statusOptions}
            gap={1}
          />
        </div>
      </div>
    </form>
  );
};

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export default memo(Filter);
