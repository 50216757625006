import { useIsFirstRender } from "@sk/hooks";
import { UtilityService } from "@sk/services";
import { BooleanCheckboxInput, SelectInput, TextInput } from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import ContactDetails from "./components/ContactDetails";

const BasicInformation = () => {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const stateVal = useWatch({
    control,
    name: ["state", "district", "communicationState", "communicationDistrict"],
  });

  const [stateList, setStateList] = useState([]);

  const [districtOptions, setDistrictOptions] = useState([]);

  const [communicationDistrictOptions, setCommunicationDistrictOptions] =
    useState([]);

  const isFirstRender = useIsFirstRender();

  const onStateChange = useCallback(
    async (val) => {
      // District List Option
      if (!isFirstRender) {
        whenAddressFieldIsDirty();
      }

      let r = await UtilityService.getDistrictList(val);
      let opt = (r.resp || []).map((e) => {
        return { label: e.name, value: e.name };
      });
      opt.unshift({ label: "Select District", value: "" });
      setDistrictOptions(opt);
    },
    [isFirstRender, whenAddressFieldIsDirty]
  );

  const onCommStateChange = useCallback(async (val) => {
    // Communication District List Options
    if (!val) {
      return;
    }
    let r = await UtilityService.getDistrictList(val);

    let opt = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    opt.unshift({ label: "Select District", value: "" });
    setCommunicationDistrictOptions(opt);
  }, []);

  useEffect(() => {
    loadStateList();
  }, [loadStateList]);

  const loadStateList = useCallback(async () => {
    let r = await UtilityService.getStateList();
    let opt = (r.resp || []).map((e) => {
      return { label: e.name, value: e.name };
    });
    opt.unshift({ label: "Select State", value: "" });
    setStateList(opt);
    if (getValues("district")) {
      onStateChange(getValues("state"));
    }
    if (getValues("communicationDistrict")) {
      onCommStateChange(getValues("communicationState"));
    }
  }, [getValues, onCommStateChange, onStateChange]);

  const onSameAddressCb = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        if (e) {
          setCommunicationDistrictOptions(districtOptions);
          setSimilarAddressForCommAddress();
        }
      };
    },
    [setSimilarAddressForCommAddress, districtOptions]
  );

  const setSimilarAddressForCommAddress = useCallback(() => {
    let d = [
      { src: "addressLine1", dst: "communicationLine1" },
      { src: "addressLine2", dst: "communicationLine2" },
      { src: "addressLandmark", dst: "communicationLandmark" },
      { src: "state", dst: "communicationState" },
      { src: "district", dst: "communicationDistrict" },
      { src: "town", dst: "communicationCity" },
      { src: "pincode", dst: "communicationPincode" },
    ];
    d.map((e) => {
      setValue(e.dst, getValues(e.src));
    });
  }, [getValues, setValue]);

  const isOfficeAddressSame = useWatch({
    control,
    name: "isOfficeAddressSame",
  });

  const whenAddressFieldIsDirty = useCallback(() => {
    setValue("isOfficeAddressSame", false);
  }, [setValue]);

  return (
    <div className="row">
      {/* Contact Details */}
      <div className="col-4 gx-5">
        <div className="col-12">
          <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
            Contact Details
          </div>
        </div>
        <ContactDetails />
      </div>

      {/* Register Office Address */}
      <div className="col-4 gx-5">
        <div className="col-12">
          <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
            Registered Office Address
          </div>
        </div>
        {/* Register Office Address */}
        <div className="row">
          {/* Line 1 */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Address Line 1"
              placeholder="Line 1"
              register={register}
              callback={whenAddressFieldIsDirty}
              name="addressLine1"
              error={errors?.addressLine1?.message}
              isMandatory={true}
            />
          </div>

          {/* Line 2 */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Address Line 2"
              placeholder="Enter Vendor Name Here"
              register={register}
              name="addressLine2"
              error={errors?.addressLine2?.message}
              isMandatory={true}
              callback={whenAddressFieldIsDirty}
            />
          </div>

          {/* State  */}
          <div className="col-12">
            {stateList?.length > 0 && (
              <SelectInput
                type="text"
                label="Choose State"
                register={register}
                name="state"
                options={stateList}
                callback={onStateChange}
                error={errors?.state?.message}
                isMandatory={true}
              />
            )}
          </div>

          {/* District */}
          <div className="col-12">
            {districtOptions?.length > 0 && (
              <SelectInput
                type="text"
                label="Choose District"
                register={register}
                name="district"
                options={districtOptions}
                error={errors?.district?.message}
                disabled={!getValues("state")?.length}
                isMandatory={true}
                callback={whenAddressFieldIsDirty}
              />
            )}
          </div>

          {/* Town */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Town"
              placeholder="Enter Town Here"
              register={register}
              name="town"
              error={errors?.town?.message}
              isMandatory={true}
              callback={whenAddressFieldIsDirty}
            />
          </div>

          {/* Landmark */}
          <div className="col-6">
            <TextInput
              type="text"
              label="Landmark"
              placeholder="Enter Landmark"
              register={register}
              name="addressLandmark"
              error={errors?.addressLandmark?.message}
              isMandatory={false}
              callback={whenAddressFieldIsDirty}
            />
          </div>

          {/* Pincode */}
          <div className="col-6">
            <TextInput
              type="number"
              label="Pincode"
              placeholder="Enter Pincode Here"
              register={register}
              name="pincode"
              error={errors?.pincode?.message}
              isMandatory={true}
              callback={whenAddressFieldIsDirty}
            />
          </div>
        </div>
      </div>
      {/* Communication Address */}
      <div className="col-4 gx-5">
        <div className="col-12">
          <div className="fw-semibold fs-val-lg pb-2 mb-4 border-bottom">
            Communication Address
            <span className="fs-val-xs text-danger">
              {!isOfficeAddressSame && "Registered Address is Different**"}
            </span>
          </div>
        </div>
        <div className="row">
          {/* Line 1 */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Address Line 1"
              placeholder="Line 1"
              register={register}
              name="communicationLine1"
              error={errors?.communicationLine1?.message}
              disabled={isOfficeAddressSame}
            />
          </div>

          {/* Line 2 */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Address Line 2"
              placeholder="Enter Vendor Name Here"
              register={register}
              name="communicationLine2"
              error={errors?.communicationLine2?.message}
              isMandatory={true}
              disabled={isOfficeAddressSame}
            />
          </div>

          {/* State  */}
          <div className="col-12">
            {stateList?.length && (
              <SelectInput
                type="text"
                label="Choose State"
                register={register}
                name="communicationState"
                options={stateList}
                callback={onCommStateChange}
                error={errors?.communicationState?.message}
                isMandatory={true}
                disabled={isOfficeAddressSame}
              />
            )}
          </div>

          {/* District */}
          <div className="col-12">
            {communicationDistrictOptions?.length ? (
              <SelectInput
                type="text"
                label="Choose District"
                register={register}
                name="communicationDistrict"
                options={communicationDistrictOptions}
                error={errors?.communicationDistrict?.message}
                disabled={isOfficeAddressSame}
                isMandatory={true}
              />
            ) : null}
          </div>

          {/* Town */}
          <div className="col-12">
            <TextInput
              type="text"
              label="Town"
              placeholder="Enter Town Here"
              register={register}
              name="communicationCity"
              error={errors?.communicationCity?.message}
              isMandatory={true}
              disabled={isOfficeAddressSame}
            />
          </div>

          {/* Landmark */}
          <div className="col-6">
            <TextInput
              type="text"
              label="Landmark"
              placeholder="Enter Landmark"
              register={register}
              name="communicationLandmark"
              error={errors?.communicationLandmark?.message}
              isMandatory={false}
              disabled={isOfficeAddressSame}
            />
          </div>

          {/* Pincode */}
          <div className="col-6">
            <TextInput
              type="number"
              label="Pincode"
              placeholder="Enter Pincode Here"
              register={register}
              name="communicationPincode"
              error={errors?.communicationPincode?.message}
              disabled={isOfficeAddressSame}
              isMandatory={true}
            />
          </div>

          {/* Same as Office Address */}
          <div className="col-12">
            <Controller
              name="isOfficeAddressSame"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BooleanCheckboxInput
                  label="Same as Office Address"
                  name="isOfficeAddressSame"
                  isMandatory={false}
                  callback={onSameAddressCb(onChange)}
                  value={value}
                  gap={0}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
