import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Committed", value: "Committed" },
  { label: "Pending", value: "Pending" },
];

const referenceTypeOptions = [
  { label: "All", value: "" },
  { label: "GRN-INTAKE", value: "GRN-INTAKE" },
  { label: "GRN-PAYMENT", value: "GRN-PAYMENT" },
  { label: "GRN-DEBITNOTE-AUTO", value: "GRN-DEBITNOTE-AUTO" },
  { label: "GRN-DEBITNOTE-MANUAL", value: "GRN-DEBITNOTE-MANUAL" },
];

const payoutTypeOptions = [
  { label: "All", value: "" },
  { label: "Credit", value: "Credit" },
  { label: "Debit", value: "Debit" },
];

const defaultFilterFormData = {
  referenceId: "",
  payoutType: "",
  createdAt: [],
  referenceType: "",
  status: "",
};

const DateConfig = {
  mode: "range",
};

const Filter = ({ callback }) => {
  const { register, control, reset, getValues } = useForm({
    defaultValues: defaultFilterFormData,
  });

  const onReset = () => {
    reset(defaultFilterFormData);
    callback({ formData: defaultFilterFormData });
  };

  const onSearch = useCallback(
    debounce(() => {
      callback({ formData: getValues() });
    }, 800),
    []
  );

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    callback({ formData: getValues() });
  };

  return (
    <form autoComplete="off">
      <div className="row">
        <div className="col-3">
          {/* Reference Id */}
          <TextInput
            name="referenceId"
            register={register}
            label="Search"
            placeholder="Search for Reference ID"
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-3">
          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                placeholder="Select Date"
                value={value}
                callback={onDateChange(onChange)}
                label="Date Range"
                config={DateConfig}
                hideClose={true}
                template={2}
                gap={1}
              />
            )}
          />
        </div>
        <div className="col-auto">
          {/* Payout Type */}
          <SelectInput
            name="payoutType"
            register={register}
            label="Payout Type"
            options={payoutTypeOptions}
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-auto">
          {/* Status */}
          <SelectInput
            name="status"
            register={register}
            label="Status"
            options={statusOptions}
            callback={onSearch}
            gap={1}
          />
        </div>
        <div className="col-auto">
          {/* Reference Type */}
          <SelectInput
            name="referenceType"
            register={register}
            label="Reference Type"
            options={referenceTypeOptions}
            callback={onSearch}
            gap={1}
          />
        </div>

        {/* Action Button */}
        <div className="col-auto align-self-end">
          <button
            className="btn btn-outline-dark fs-val-md mb-1"
            type="button"
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  );
};

export default memo(Filter);
